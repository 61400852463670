import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import DesktopLayout from "./partials/desktop.layout";
import MobileLayout from "./partials/mobile.layout";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ProductIcon from '@mui/icons-material/Sell';
import OrderBumpIcon from '@mui/icons-material/Discount';
import WebhookIcon from '@mui/icons-material/Webhook';
import AppsIcon from '@mui/icons-material/Apps';
import MarketplaceIcon from '@mui/icons-material/Store';
import CommissionRuleIcon from '@mui/icons-material/EmojiEvents';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import { Link, useNavigate } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReportIcon from '@mui/icons-material/ReceiptLong';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AffiliatedsIcon from '@mui/icons-material/Groups2';
import { useEffect, useRef } from "react";
import ContentDialog from "../components/Dialogs/ContentDialog";
import React from "react";
import { decodeToken } from "react-jwt";
import { AuthContext } from "../core/context/AuthContext";
import { TAuthTenant } from "../core/types/AuthTenant";
import { useCurrentTenantBank } from "../core/hooks/useCurrentTenantBank";
import { useCurrentTenant } from "../core/hooks/useCurrentTenant";
import { TUserType, userTypeAll, userTypeNotSysAdmin, userTypeSysAdmin } from "../core/types/Auth";
import HotjarFunctions from "../core/functions/HotjarFunctions";
import TagFunctions from "../core/functions/TagFunctions";

const menus = [
  {
    name: "Dashboard",
    to: "/dashboard",
    icon: <DashboardIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Produtos",
    to: "/products",
    icon: <ProductIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Order Bumps",
    to: "/orderbumps",
    icon: <OrderBumpIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Afiliados",
    to: "/affiliateds",
    icon: <AffiliatedsIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Regras de Comissões",
    to: "/commissionrules",
    icon: <CommissionRuleIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Apps",
    to: "/apps",
    icon: <AppsIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Funil de Vendas",
    to: "/sales/funnel",
    icon: <FunnelIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  },
  {
    name: "Marketplace",
    to: "/marketplace",
    icon: <MarketplaceIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  }
];

const reports = [
  {
    name: "Vendas",
    to: "/checkout/order",
    icon: <ReportIcon color="primary" />,
    permissions: userTypeAll,
  },
  {
    name: "Assinaturas",
    to: "/checkout/subscription",
    icon: <ReportIcon color="primary" />,
    permissions: userTypeAll,
  },
  {
    name: "Análise Vendas",
    to: "/checkout/analytics",
    icon: <ReportIcon color="primary" />,
    permissions: userTypeSysAdmin,
  },
  {
    name: "Acessos Checkout",
    to: "/checkout/access",
    icon: <ReportIcon color="primary" />,
    permissions: userTypeSysAdmin,
  },
  {
    name: "Webhooks Enviados",
    to: "/webhooks/sends",
    icon: <WebhookIcon color="primary" />,
    permissions: userTypeNotSysAdmin,
  }
];

const configs = [
  {
    name: "Configurações",
    to: "/settings",
    icon: <ManageAccountsIcon color="primary" />,
    permissions: userTypeAll,
  }
];

const system = [
  {
    name: "Usuários",
    to: "/settings/users",
    icon: <SupervisorAccountIcon color="primary" />,
    permissions: userTypeSysAdmin,
  }
];

export default function MainLayout() {
  const { user, setUser } = React.useContext(AuthContext);
  const json = decodeToken(user?.token ?? '');
  const authTenant = json as TAuthTenant;

  const navigate = useNavigate();

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const [selected, setSelected] = React.useState("");

  const userMenus = menus.filter(m => m.permissions.includes(user?.UserType || TUserType.Member));
  const userReports = reports.filter(m => m.permissions.includes(user?.UserType || TUserType.Member));
  const userConfigs = configs.filter(m => m.permissions.includes(user?.UserType || TUserType.Member));
  const userSystem = system.filter(m => m.permissions.includes(user?.UserType || TUserType.Member));

  const drawer = (
    <div>
      {userMenus.length > 0 && (
        <List dense={true}>
          {userMenus.map((menu) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, px: 1, py: 0.2 }}
            >
              <ListItemButton
                disableGutters
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{ borderRadius: 1, px: 1 }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {userMenus.length > 0 && <Divider />}
      {userReports.length > 0 && (
        <List dense={true}>
          {userReports.map((menu, index) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, px: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{ borderRadius: 1, px: 1 }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {userReports.length > 0 && <Divider />}
      {userSystem.length > 0 && (
        <List dense={true}>
          {userSystem.map((menu, index) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, px: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{ borderRadius: 1, px: 1 }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {userSystem.length > 0 && <Divider />}
      {userConfigs.length > 0 && (
        <List dense={true}>
          {userConfigs.map((menu, index) => (
            <ListItem
              disablePadding
              key={menu.name}
              sx={{ borderRadius: 1, px: 1, py: 0.2 }}
            >
              <ListItemButton
                component={Link}
                to={menu.to}
                selected={selected === menu.name}
                sx={{ borderRadius: 1, px: 1 }}
                onClick={() => setSelected(menu.name)}
              >
                <ListItemIcon>
                  {menu.icon}
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );

  useEffect(() => {
    const tagHotjarDash = HotjarFunctions.addTagDash();
    return () => TagFunctions.removeTags({ script: tagHotjarDash });
  }, []);

  return (
    <Box>
      {windowSize.current[0] <= 600 && (
        <MobileLayout drawer={drawer} />
      )}
      {windowSize.current[0] > 600 && (
        <DesktopLayout drawer={drawer} />
      )}
    </Box>
  );
}
