import { Box, Button, Popover, Typography } from "@mui/material";
import { useState } from "react";
import { SketchPicker } from "react-color";

export interface IColorChooseProps {
    name: string;
    color?: string;
    onChange?: (value: string) => void;
}

const ColorChoose = ({ name, color, onChange }: IColorChooseProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? `simple-popover-${name.toLowerCase().replaceAll(" ", "_")}` : undefined;

    return (
        <>
            <Typography>{name}</Typography>
            <Button
                onClick={handleClick}
                sx={{
                    p: 0,
                    textTransform: 'none',
                    msTransform: 'none',
                }}
            >
                <Box
                    sx={{
                        width: 90,
                        height: 30,
                        borderRadius: 1,
                        backgroundColor: color,
                        border: 1,
                        borderColor: 'grey',
                    }}
                />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <SketchPicker
                    color={color}
                    onChangeComplete={(e) => {
                        if (onChange) {
                            onChange(e.hex);
                        }
                    }}
                />
            </Popover>
        </>
    );
}

export default ColorChoose;