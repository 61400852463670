import { TProductPricePaymentInstallment } from "./Product";
import { TSaleAddress } from "./Sale";

export type IOrder = {
    productPriceId: string;
    name: string;
    document: string;
    email: string;
    phone: string;
    address: TSaleAddress;
    paymentData: PaymentData;
    orderBumps?: TOrderBump[];
}

export type IOrderAddress = {
    city: string,
    state: string,
    country: string,
    street: string,
    number: string,
    neighborhood: string,
    zipCode: string,
    complement: string,
    reference: string,
}

export type TOrderBump = {
    productPriceId: string;
    description: string;
    amount: number;
    value: number;
}

export type PaymentData = {
    paymentMethod: PaymentMethodEnum;
    creditCardData?: ICreditCard;
    debitCardData?: IDebitCard;
    pixData?: IPix;
    boletoData?: IBoleto;

}

export type ICreditCard = {
    name: string;
    number: string;
    installments: number;
    month: number;
    year: number;
    cvv: string;
}

export type IDebitCard = {
    name: string;
    number: string;
    month: number;
    year: number;
    cvv: string;
}

export type IPix = {
    // Data de expiração do Pix em segundos, Ex: 52134613.  Obrigatório se não preencher ExpiresAt
    expiresIn: number;

    // 	Data de expiração do Pix. Obrigatório se não preencher ExpiresIn
    expiresAt: Date;
}

export type IBoleto = {
    // Instruções do boleto. Max: 256 caracteres.
    instructions: string;

    // Identificador do boleto. Max: 16 caracteres.
    documentNumber: string;

    // Tipo de espécie do boleto.DM (Duplicata Mercantil) e BDP (Boleto de proposta)
    type: string;

    // Código do banco. Ex.: 001 (Banco do Brasil); 033 (Santander); 104 (Caixa Econômica Federal);
    // 197 (Banco Stone); 198 (Pagar.me); 237 (Bradesco); 341 (Itau); 376 (JPMorgan); 745 (Citibank) .
    bank?: string;

    // Data de vencimento. (Opcional)
    dueAt?: Date;
}

export type IOrderResponse = {
    success: boolean;
    orderId?: string;
    chargeId?: string;
    paymentType: number;
    status?: string;
    message?: string;
    urlRedirect?: string;

    pixQrCode?: string;
    pixQrUrl?: string;

    boletoUrl?: string;
    boletoCodeBar?: string;
    boletoCodeBarUrl?: string;
    boletoPDF?: string;
    boletoQrCode?: string;
}

export enum PaymentMethodEnum {
    CreditCard = 1,
    DebitCard = 2,
    Pix = 3,
    Boleto = 4,
}

export enum IOrderChargeStatus {
    Pending = 'pending',
    Paid = 'paid',
    Canceled = 'canceled',
    Processing = 'processing',
    Failed = 'failed',
    Overpaid = 'overpaid',
    Underpaid = 'underpaid',
    Chargedback = 'chargedback',
}

export type IOrderCharge = {
    id: string;
    chargeId: string;
    code: string;
    status: IOrderChargeStatus;
    paymentMethod: string;
    transactionId: string;
    orderId: string;
}

export type ICalculateInstallment = {
    installments: Array<TProductPricePaymentInstallment>;
}
