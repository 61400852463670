import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { blue } from "@mui/material/colors";
import { useState } from "react";

export interface IImageChooseProps {
  title: string;
  imageUrl: string | undefined;
  imageCode: string | undefined;
  imageName: string | undefined;
  onChange: (file: File | undefined, code: string | undefined) => Promise<void>;
  onError: (error: string) => void;
  dimenssionsText?: string;
}

const ImageChoose = ({
  title,
  imageUrl,
  imageName,
  imageCode,
  onChange,
  onError,
  dimenssionsText,
}: IImageChooseProps) => {
  const [loaded, setLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  //const [preview, setPreview] = useState<string | undefined>();

  const imageStyle = !loaded
    ? { display: "none" }
    : {
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: 5,
        margin: 0,
        padding: 0,
      };

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const handleRemoveImage = () => {
    setSelectedFile(undefined);
    onChange(undefined, imageCode);
  };

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      //setSelectedFile(undefined);
      //onChange(undefined, imageName);
      return;
    }

    const file = e.target.files[0];

    if (file.size >= 1056895) {
      e.target.value = "";
      onError("A imagem precisa ter um tamanho menor que 1 MB");
      return;
    }

    setSelectedFile(file);
    onChange(file, undefined);
    e.target.value = "";
  };

  return (
    <Box>
      <Typography gutterBottom>{title}</Typography>
      <Button fullWidth variant="text" component="label">
        <Paper
          sx={{
            width: "100%",
            height: 200,
            border: `2px solid ${blue[700]}`,
            borderStyle: "dashed",
            borderRadius: 2,
            mb: 2,
          }}
        >
          <Stack
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {(!imageUrl || imageUrl === "") && (
              <>
                <AddPhotoAlternateIcon
                  sx={{ mb: 1, fontSize: "2.5rem", color: "grey" }}
                />
                <Typography variant="caption" fontWeight={600} color="primary">
                  Selecione do computador
                </Typography>
                <Typography variant="caption" color="grey">
                  PNG, JPG até 1MB
                </Typography>
                {dimenssionsText && (
                  <Typography variant="caption" color="grey">
                    {dimenssionsText}
                  </Typography>
                )}
              </>
            )}
            {imageUrl && imageUrl !== undefined && (
              <img
                src={imageUrl}
                alt={imageName}
                style={imageStyle}
                onLoad={handleImageLoaded}
              />
            )}
          </Stack>
        </Paper>
        <input
          id={`input-file-${imageCode}`}
          type="file"
          onChange={onSelectFile}
          hidden
          accept="image/*"
        />
      </Button>
      {imageUrl && imageUrl !== undefined && (
        <Button
          fullWidth
          variant="text"
          component="label"
          color="error"
          onClick={handleRemoveImage}
          sx={{ mb: 1 }}
        >
          Remover imagem
        </Button>
      )}
    </Box>
  );
};

export default ImageChoose;
