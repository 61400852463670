import { Box, Divider, Paper, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";

const CheckoutPlaceholder = () => {
    const InputItem = () => {
        return <Paper elevation={0} sx={{ width: "100%", height: 45, backgroundColor: "silver" }} />;
    };

    const MethodPaymentItem = () => {
        return <Paper elevation={0} sx={{ width: "100%", height: 60, backgroundColor: "silver" }} />;
    };

    const ButtonItem = () => {
        return <Paper elevation={0} sx={{ width: 250, height: 45, backgroundColor: "silver" }} />;
    };

    return (
        <Paper
            sx={{
                width: "100%",
                backgroundColor: "white",
                borderRadius: "8px",
                ":hover": {
                    backgroundColor: grey[300],
                },
            }}
        >
            <Stack
                sx={{
                    width: "100%",
                    pt: 2,
                    pb: 5,
                }}
            >
                <Divider />
                <Stack direction="column" spacing={3} sx={{ px: 3, pt: 10, pb: 3 }}>
                    <Divider />
                    <InputItem />
                    <InputItem />
                    <Stack direction="row" spacing={2}>
                        <InputItem />
                        <InputItem />
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ pt: 3 }}>
                        <MethodPaymentItem />
                        <MethodPaymentItem />
                        <MethodPaymentItem />
                    </Stack>
                    <InputItem />
                    <InputItem />
                    <Stack direction="row" spacing={2} sx={{ pt: 3 }} justifyContent="center">
                        <ButtonItem />
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    );
};

export default CheckoutPlaceholder;
