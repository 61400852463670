import { Paper, Stack } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

export interface ICheckoutHeaderProps {
  height?: string | number;
}

const CheckoutHeader = ({ height = 120 }: ICheckoutHeaderProps) => {
  return (
    <Paper
      sx={{
        width: "100%",
        height: height,
        border: `2px solid ${blue[700]}`,
        borderStyle: "dashed",
        borderRadius: "8px",
        backgroundColor: "white",
        color: grey[800],
      }}
    >
      <Stack
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        Arraste um componente aqui...
      </Stack>
    </Paper>
  );
};

export default CheckoutHeader;
