import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentType,
  TCustomCheckoutLayout,
} from "../../core/types/CustomCheckout";
import StopWatchWidget from "../Checkout/StopWatch";
import CheckoutComponenetList from "./CheckoutComponentList";
import CheckoutEmpty from "./CheckoutEmpty";
import CheckoutHeader from "./CheckoutHeader";
import CheckoutImage from "./CheckoutImage";
import CheckoutLayout2_3 from "./CheckoutLayout2_3";
import CheckoutPlaceholder from "./CheckoutPlaceholder";
import CheckoutText from "./CheckoutText";

export interface ICheckoutComponenetWidgetProps {
  layout: TCustomCheckoutLayout;
  item: TCustomCheckoutComponentItem;
  dragOverItem: React.MutableRefObject<string>;
  dragOverItemOld: React.MutableRefObject<string>;
  parentList: TCustomCheckoutComponentItem[];
  mouseEnterItemId: string;
  setList: (value: TCustomCheckoutComponentItem[]) => void;
  onEdit: (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.DragEvent<HTMLLIElement>,
    value: TCustomCheckoutComponentItem
  ) => void;
  onDelete: (value: TCustomCheckoutComponentItem) => void;
  setCurrentComponenets: (
    value: TCustomCheckoutComponentItem[] | undefined
  ) => void;
  setMouseEnterItemId: React.Dispatch<React.SetStateAction<string>>;
  onDropItem: (
    e: React.DragEvent<HTMLDivElement>,
    dragItem: React.MutableRefObject<string>
  ) => void;
}

const CheckoutComponenetWidget = ({
  layout,
  item,
  dragOverItem,
  dragOverItemOld,
  parentList,
  mouseEnterItemId,
  setList,
  onEdit,
  onDelete,
  setCurrentComponenets,
  setMouseEnterItemId,
  onDropItem,
}: ICheckoutComponenetWidgetProps) => {
  switch (item.type) {
    case TCustomCheckoutComponentType.checkout:
      return <CheckoutPlaceholder />;
    case TCustomCheckoutComponentType.stopWatch:
      return (
        <StopWatchWidget
          initialTime={item.milleseconds ?? 900000}
          text={item.title}
          textFinished={item.subtitle}
          backgroundColor={item.sx.backgroundColor}
          color={item.sx.color}
        />
      );
    case TCustomCheckoutComponentType.layout2_3:
      return (
        <CheckoutLayout2_3
          layout={layout}
          item={item}
          parentList={parentList}
          mouseEnterItemId={mouseEnterItemId}
          dragOverItem={dragOverItem!}
          dragOverItemOld={dragOverItemOld!}
          setList={setList}
          onEditItem={onEdit}
          onDeleteItem={onDelete}
          setCurrentComponenets={setCurrentComponenets}
          setMouseEnterItemId={setMouseEnterItemId}
          onDropItem={onDropItem}
        />
      );
    case TCustomCheckoutComponentType.list:
      return (
        <CheckoutComponenetList
          layout={layout}
          list={item.children}
          dragOverItem={dragOverItem!}
          dragOverItemOld={dragOverItemOld!}
          parentList={parentList}
          mouseEnterItemId={mouseEnterItemId}
          setList={setList}
          onEdit={onEdit!}
          onDelete={onDelete}
          setCurrentComponenets={setCurrentComponenets}
          setMouseEnterItemId={setMouseEnterItemId}
          onDropItem={onDropItem}
        />
      );
    case TCustomCheckoutComponentType.text:
      return <CheckoutText text={item.title} />;
    case TCustomCheckoutComponentType.image:
      return (
        <CheckoutImage
          imageUrl={item.imageUrl}
          alt={item.imageName ?? item.id}
        />
      );
    case TCustomCheckoutComponentType.empty:
      return <CheckoutEmpty />;
    default:
      return <CheckoutHeader height={item.sx.height} />;
  }
};

export default CheckoutComponenetWidget;
