import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./core/context/AuthContext";
import Routes from "./routes";
import { ThemeProvider } from "@mui/material";
import theme from "./layouts/Theme";
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
