import { grey } from "@mui/material/colors"
import { CreditCardAmerican } from "../models/CreditCardAmerican"
import { CreditCardDiners } from "../models/CreditCardDiners"
import { CreditCardDiscover } from "../models/CreditCardDiscover"
import { CreditCardElo } from "../models/CreditCardElo"
import { CreditCardJCB } from "../models/CreditCardJCB"
import { CreditCardMaster } from "../models/CreditCardMaster"
import { CreditCardVisa } from "../models/CreditCardVisa"
import {
  TCustomCheckout,
  TCustomCheckoutComponentDevice,
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentItemSX,
  TCustomCheckoutComponentType,
} from "../types/CustomCheckout"
import { IOrderChargeStatus } from "../types/Order"
import { TTenantAnticipationType } from "../types/Tenant"
import theme from "../../layouts/Theme"
import NumberFunctions from "../functions/NumberFunctions"

export const methodTypeCreditCard = 1
export const methodTypeDebitCard = 2
export const methodTypePix = 3
export const methodTypeBoleto = 4

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const paymentsPaid = [IOrderChargeStatus.Paid]

export const paymentsPendent = [
  IOrderChargeStatus.Overpaid,
  IOrderChargeStatus.Underpaid,
]

export const paymentsFailed = [
  IOrderChargeStatus.Failed,
  IOrderChargeStatus.Canceled,
  IOrderChargeStatus.Chargedback,
]

export const startNumberVisa = ["4"]

export const startNumberMaster = ["51", "52", "53", "54", "55"]

export const startNumberElo = ["50", "56", "57", "58", "59"]

export const startNumberDiners = ["30", "36", "38"]

export const startNumberDiscover = ["6011", "65"]

export const startNumberJCB = ["35"]

export const startNumberAmerican = ["34", "37"]

export const creditsCards = [
  new CreditCardVisa(),
  new CreditCardMaster(),
  new CreditCardElo(),
  new CreditCardDiners(),
  new CreditCardDiscover(),
  new CreditCardJCB(),
  new CreditCardAmerican(),
]

export const tenantAnticipationTypes = [
  TTenantAnticipationType.None,
  TTenantAnticipationType.D2,
  TTenantAnticipationType.D15,
  TTenantAnticipationType.D30,
]

export const creditCardInstallmentFee = 2.99

export const newCustomCheckoutEmpty: TCustomCheckoutComponentItem = {
  id: NumberFunctions.getRandomInt(99999).toString(),
  name: "Vazio",
  order: 0,
  type: TCustomCheckoutComponentType.empty,
  sx: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  title: "",
  subtitle: "",
  fixedTop: false,
  children: [],
}

export const newCustomCheckoutText: TCustomCheckoutComponentItem = {
  id: NumberFunctions.getRandomInt(999999).toString(),
  name: "Digite seu texto aqui...",
  order: 0,
  type: TCustomCheckoutComponentType.text,
  sx: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  title:
    '[{"id":"7c122cf7-d47d-4b15-8526-ef0166261412","type":"paragraph","props":{"textColor":"default","backgroundColor":"default","textAlignment":"left"},"content":[{"type":"text","text":"Digite seu texto aqui...","styles":{}}],"children":[]},{"id":"a87c65ed-b8f2-420f-a3b7-fc2991dd3ff7","type":"paragraph","props":{"textColor":"default","backgroundColor":"default","textAlignment":"left"},"content":[],"children":[]}]',
  subtitle: "",
  fixedTop: false,
  children: [],
}

export const customCheckoutSettingsDefault: TCustomCheckoutComponentItemSX = {
  color: "black",
  backgroundColor: grey[100],
  backgroundImageFixedTop: false,
  backgroundImageRepeat: false,
  backgroundImageExpand: false,
}

export const customCheckoutComponentsDefault: TCustomCheckoutComponentDevice = {
  settings: { ...customCheckoutSettingsDefault },
  components: [
    {
      id: "1",
      name: "Header",
      order: 1,
      type: TCustomCheckoutComponentType.none,
      sx: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      title: "",
      subtitle: "",
      fixedTop: false,
      children: [],
    },
    {
      id: "2",
      name: "Main",
      order: 2,
      type: TCustomCheckoutComponentType.layout2_3,
      sx: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
      title: "",
      subtitle: "",
      fixedTop: false,
      children: [
        {
          id: "3",
          name: "Checkout",
          order: 1,
          type: TCustomCheckoutComponentType.checkout,
          sx: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
          },
          title: "",
          subtitle: "",
          fixedTop: false,
          children: [],
        },
        {
          id: "4",
          name: "Lateral",
          order: 2,
          type: TCustomCheckoutComponentType.list,
          sx: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            height: "100%",
          },
          title: "",
          subtitle: "",
          fixedTop: false,
          children: [
            {
              id: "5",
              name: "Header",
              order: 2,
              type: TCustomCheckoutComponentType.none,
              sx: {
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
              },
              title: "",
              subtitle: "",
              fixedTop: false,
              children: [],
            },
          ],
        },
      ],
    },
  ],
}

export const customCheckoutDefault: TCustomCheckout = {
  id: "",
  name: "Padrão",
  productId: "",
  productName: "",
  prices: [],
  defaultValue: true,
  desktopLayout: { ...customCheckoutComponentsDefault },
  mobileLayout: { ...customCheckoutComponentsDefault },
}
