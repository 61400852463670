import { Alert, Snackbar } from "@mui/material";

export interface ISuccessSnackbarProps {
    open: boolean;
    children: string | string[] | JSX.Element;
    onClose: () => void;
    autoHideDuration?: number;
}

const SuccessSnackbar = ({ open, onClose, children, autoHideDuration }: ISuccessSnackbarProps) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration || 4000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                variant="filled"
                severity="success"
                onClose={onClose}
                sx={{ width: '100%' }}
            >
                {children}
            </Alert>
        </Snackbar>
    );
}

export default SuccessSnackbar;