export enum TCustomCheckoutLayout {
  desktop = 1,
  mobile = 2,
}

export type TCustomCheckoutPagination = {
  items: TCustomCheckout[];
  page: number;
  rowsPerPage: number;
  totalPages: number;
  total: number;
};

export enum TCustomCheckoutComponentType {
  none = 0,
  checkout = 1,
  text = 2,
  image = 3,
  stopWatch = 4,
  layout1 = 5,
  layout1_2 = 6,
  layout2_3 = 7,
  list = 8,
  empty = 9,
}

export type TCustomCheckoutComponent = {
  name: string;
  icon: JSX.Element;
  type: TCustomCheckoutComponentType;
};

export type TCustomCheckoutComponentItemSX = {
  backgroundColor?: string;
  color?: string;
  height?: string | number;

  backgroundImage?: string;
  backgroundImageCode?: string;
  backgroundImageName?: string;
  backgroundImageExt?: string;
  backgroundImageFixedTop?: boolean;
  backgroundImageRepeat?: boolean;
  backgroundImageExpand?: boolean;
};

export type TCustomCheckoutComponentItem = {
  id: string;
  name: string;
  order: number;
  type: TCustomCheckoutComponentType;

  sx: TCustomCheckoutComponentItemSX;

  title: string;
  subtitle: string;

  fixedTop: boolean;

  hours?: number;
  minutes?: number;
  seconds?: number;
  milleseconds?: number;

  imageUrl?: string;
  imageCode?: string;
  imageName?: string;
  imageExt?: string;

  children: TCustomCheckoutComponentItem[];
};

export enum TModelType {
  none = 1,
  image = 2,
  imageText = 3,
}

export enum TClickAction {
  discount = 1,
  redirectUrl = 2,
  onlyClose = 3,
}

export type TPopupConfig = {
  modelType: TModelType;
  actionClick?: TClickAction;
  mainText?: string;
  auxText?: string;
  textButton?: string;
  imageUrl?: string;
  imageCode?: string;
  imageName?: string;
  imageExt?: string;
  actionValue?: string;
};

export type TCustomCheckoutComponentDevice = {
  settings: TCustomCheckoutComponentItemSX;
  components: TCustomCheckoutComponentItem[];
  popupConfig?: TPopupConfig;
};

export type TCustomCheckout = {
  id: string;
  name: string;
  productId: string;
  productName?: string;
  prices: string[];
  defaultValue: boolean;
  desktopLayout?: TCustomCheckoutComponentDevice;
  mobileLayout?: TCustomCheckoutComponentDevice;
};
