import { Button, Container, Stack, Typography } from "@mui/material";
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Stack
                direction={"column"}
                width={"100%"}
                height="90vh"
                sx={{ p: 3 }}
                spacing={3}
                alignItems="center"
                justifyContent="center"
            >
                <Stack direction="row" spacing={3} alignContent="center" alignItems="center">
                    <WebAssetOffIcon color="primary" sx={{ fontSize: "5rem" }} />
                    <Stack direction="column">
                        <Typography variant="h4" fontWeight={500}>Ops, página não encontrada!</Typography>
                        <Typography variant="body1">Desculpa, a página que tentou acessar não foi encontrada.</Typography>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    );
}

export default NotFoundPage;