import {
  Box,
  Button,
  IconButton,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentType,
  TCustomCheckoutLayout,
} from "../../core/types/CustomCheckout";
import CheckoutComponenetWidget from "./CheckoutComponentWidget";
import { useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckoutFunctions from "../../core/functions/CheckoutFunctions";
import { newCustomCheckoutEmpty } from "../../core/constants";
import NumberFunctions from "../../core/functions/NumberFunctions";

export interface ICheckoutComponenetListProps {
  layout: TCustomCheckoutLayout;
  list: TCustomCheckoutComponentItem[];
  dragOverItem: React.MutableRefObject<string>;
  dragOverItemOld: React.MutableRefObject<string>;
  parentList: TCustomCheckoutComponentItem[];
  mouseEnterItemId: string;
  setList: (value: TCustomCheckoutComponentItem[]) => void;
  onEdit: (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.DragEvent<HTMLLIElement>,
    value: TCustomCheckoutComponentItem
  ) => void;
  onDelete: (value: TCustomCheckoutComponentItem) => void;
  setCurrentComponenets: (
    value: TCustomCheckoutComponentItem[] | undefined
  ) => void;
  setMouseEnterItemId: React.Dispatch<React.SetStateAction<string>>;
  onDropItem: (
    e: React.DragEvent<HTMLDivElement>,
    dragItem: React.MutableRefObject<string>
  ) => void;
}

const CheckoutComponenetList = ({
  layout,
  list,
  dragOverItem,
  dragOverItemOld,
  parentList,
  mouseEnterItemId,
  setList,
  onEdit,
  onDelete,
  setCurrentComponenets,
  setMouseEnterItemId,
  onDropItem,
}: ICheckoutComponenetListProps) => {
  const dragItem = useRef("");

  const dragStart = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    //console.log(`list dragStart ${dragOverItem.current}`);
    dragItem.current = list[position].id;
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>, position: number) => {
    dragOverItemOld.current = dragOverItem.current;
    dragOverItem.current = list[position].id;

    let copyList = CheckoutFunctions.clearEmpty(list);
    const { parent, components, component, index } =
      CheckoutFunctions.getComponent(
        undefined,
        copyList,
        dragOverItemOld.current
      );

    if (component?.type === TCustomCheckoutComponentType.empty) {
      return;
    }

    if (components) {
      copyList = components.filter(
        (item) => item.type !== TCustomCheckoutComponentType.empty
      );
    }

    copyList.splice(index, 0, {
      ...newCustomCheckoutEmpty,
      id: NumberFunctions.getRandomInt(99999).toString(),
    });

    let order = 0;
    copyList.map((item) => {
      order += 1;
      item.order = order;
    });

    copyList = copyList.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    if (!parent) {
      setList([...copyList]);
    } else {
      let newList = CheckoutFunctions.clearEmpty(list);
      newList = newList.map((l) =>
        l.id === parent.id
          ? { ...parent, children: copyList }
          : {
              ...l,
              children: l.children.map((c) =>
                c.id === parent.id
                  ? { ...parent, children: copyList }
                  : { ...c }
              ),
            }
      );
      setList(newList);
    }

    setCurrentComponenets(list);
  };

  const mouseEnter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    position: number
  ) => {
    //console.log(`list mouseEnterItem ${dragOverItem.current}`);
    if (mouseEnterItemId !== list[position].id) {
      setMouseEnterItemId(list[position].id);
    }
  };

  const mouseLeave = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    position: number
  ) => {
    //console.log(`list mouseLeave ${dragOverItem.current}`);
    setMouseEnterItemId("");
  };

  return (
    <Box>
      <List>
        {list
          .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
          .map((item, index) => (
            <ListItemButton
              draggable
              disableGutters
              disableRipple
              disableTouchRipple
              key={item.id}
              //onClick={(e) => onEdit(e, item)}
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              onDragLeave={(e) => {
                if (dragOverItem.current === list[index].id) {
                  dragOverItem.current = dragOverItemOld.current;
                }
              }}
              onDragEnd={(e) => onDropItem(e, dragItem)}
              onMouseEnter={(e) => mouseEnter(e, index)}
              onMouseLeave={(e) => mouseLeave(e, index)}
              sx={{
                p: 1,
                m: 0,
                margin: 0,
                border: 1,
                borderColor: "transparent",
                ":hover": {
                  border: 1,
                  borderColor: "blue",
                },
              }}
            >
              <CheckoutComponenetWidget
                layout={layout}
                item={item}
                parentList={parentList}
                mouseEnterItemId={mouseEnterItemId}
                dragOverItem={dragOverItem}
                dragOverItemOld={dragOverItemOld}
                setList={setList}
                onEdit={onEdit}
                onDelete={onDelete}
                setCurrentComponenets={setCurrentComponenets}
                setMouseEnterItemId={setMouseEnterItemId}
                onDropItem={onDropItem}
              />

              {mouseEnterItemId === item.id && (
                <Box
                  sx={{
                    backgroundColor: "blue",
                    position: "absolute",
                    right: 0,
                    top: 0,
                    boxShadow: 5,
                  }}
                >
                  <Stack direction="row" spacing={1} sx={{ px: 1 }}>
                    {item.type !== TCustomCheckoutComponentType.none && (
                      <IconButton size="small" onClick={(e) => onEdit(e, item)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {item.type !== TCustomCheckoutComponentType.layout2_3 && (
                      <IconButton size="small" onClick={() => onDelete(item)}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Stack>
                </Box>
              )}
            </ListItemButton>
          ))}
      </List>
    </Box>
  );
};

export default CheckoutComponenetList;
