import api, { getResponseError } from "../../services/api";
import { IResponse, TResponse } from "../types/ErrorResponse";
import { TImageUploaded } from "../types/Image";

const upload = async (file: File): Promise<TResponse<TImageUploaded>> => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const { data: response } = await api.post<TImageUploaded>(`/FileUpload`, formData, config);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const deleteImage = async ({ code }: { code: string }): Promise<IResponse> => {
    try {
        //await api.delete(`/FileUpload/${code}`);
        return {
            success: true,
            code: 0,
            error: '',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const ImageController = {
    upload,
    deleteImage,
}

export default ImageController;