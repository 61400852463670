import api, { getResponseError } from "../../services/api";
import { TAffiliated, TAffiliatedCommission, TAffiliatedPagination, TAffiliatedSimple, TAffiliatedStatus } from "../types/Affiliated";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";
import { TProductAffiliates, TProdutAffiliatesCategory } from "../types/ProductAffiliates";
import { TMarketplacePagination } from "../types/Marketplace";
import { numberOnly } from "../../masks/masks";

export interface IGetMarketplaceProp {
  page: number;
  rows: number;
  search?: string;
  category?: TProdutAffiliatesCategory;
  priceRange: number[];
  commissionRange: number[];
}

export interface IGetProp {
  page: number;
  rows: number;
  search?: string;
  status?: TAffiliatedStatus;
}

const insertProduct = async ({ data }: { data: TProductAffiliates }): Promise<IResponse> => {
  try {
    const json = {
      productId: data.productId,
      approveRequestManually: data.approveRequestManually || false,
      receiveEmailNotification: data.receiveEmailNotification || false,
      viewBuyerContact: data.viewBuyerContact || false,
      exposeOnMarketplace: data.exposeOnMarketplace || false,
      supportPhone: numberOnly(data.supportPhone),
      description: data.description,
      commissionPercentage: data.commissionPercentage,
      globalCommissionPercentage: data.globalCommissionPercentage,
      category: data.category,
      allowRegistrationThanksPages: data.allowRegistrationThanksPages,
    };

    const { data: response } = await api.post("/Affiliation/productAffiliation", json);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const updateProduct = async ({ data }: { data: TProductAffiliates }): Promise<IResponse> => {
  try {
    const json = {
      id: data.id,
      productId: data.productId,
      approveRequestManually: data.approveRequestManually || false,
      receiveEmailNotification: data.receiveEmailNotification || false,
      viewBuyerContact: data.viewBuyerContact || false,
      exposeOnMarketplace: data.exposeOnMarketplace || false,
      supportPhone: numberOnly(data.supportPhone),
      description: data.description,
      commissionPercentage: data.commissionPercentage,
      globalCommissionPercentage: data.globalCommissionPercentage,
      category: data.category,
      allowRegistrationThanksPages: data.allowRegistrationThanksPages,
    };

    const { data: response } = await api.put("/Affiliation/productAffiliation", json);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const removeProduct = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(`/Affiliation/productAffiliation/${id}`);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const insertAffiliated = async ({ data }: { data: TAffiliated }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post("/Affiliation/affiliated", data);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const updateAffiliated = async ({ data }: { data: TAffiliated }): Promise<IResponse> => {
  try {
    const { data: response } = await api.put("/Affiliation/affiliated", data);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const updateCommission = async ({ data }: {
  data: {
    affiliatedId: string,
    commissionPercentage?: number,
    globalCommissionPercentage?: number
  }
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post("/Affiliation/affiliated/commission", data);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const enabledCommission = async ({ data }: { data: { affiliatedId: string, isEnabled: boolean } }): Promise<IResponse> => {
  try {
    const { data: response } = await api.put("/Affiliation/affiliated/target/commission", data);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const getAll = async ({ page, rows, search, status }: IGetProp): Promise<TAffiliatedPagination | IErrorResponse> => {
  try {
    var path = `/Affiliation/affiliated?PageNumber=${page}&RowsPerPage=${rows}`;

    if (search && search !== '') {
      path += `&Search=${search}`;
    }

    if (status) {
      path += `&Status=${status}`;
    }

    const { data } = await api.get<TAffiliatedPagination>(path);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getAllBlocked = async ({ page, rows, search }: IGetProp): Promise<TAffiliatedPagination | IErrorResponse> => {
  try {
    var path = `/Affiliation/affiliated/blocked?PageNumber=${page}&RowsPerPage=${rows}`;
    if (search && search !== '') {
      path += `&Search=${search}`;
    }

    const { data } = await api.get<TAffiliatedPagination>(path);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getProduct = async ({ id }: { id: string }): Promise<TProductAffiliates | IErrorResponse> => {
  try {
    const { data } = await api.get<TProductAffiliates>(`/Affiliation/productAffiliation/${id}`);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getProducts = async ({ page, rows, search, status }: IGetProp): Promise<TAffiliatedPagination | IErrorResponse> => {
  try {
    var path = `/Affiliation/affiliated/myaffiliations?PageNumber=${page}&RowsPerPage=${rows}`;

    if (search && search !== '') {
      path += `&Search=${search}`;
    }

    if (status != undefined && status !== TAffiliatedStatus.All) {
      path += `&Status=${status}`;
    }

    const { data } = await api.get<TAffiliatedPagination>(path);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getMarketplace = async ({ page, rows, search, category, priceRange, commissionRange }: IGetMarketplaceProp): Promise<TMarketplacePagination | IErrorResponse> => {
  try {
    var path = `/Affiliation/marketplace?PageNumber=${page}&RowsPerPage=${rows}`;

    if (search && search !== '') {
      path += `&Search=${search}`;
    }

    if (category && category !== null) {
      path += `&Category=${category}`;
    }

    if (priceRange.length === 2) {
      path += `&firstPrice=${priceRange[0]}&lastPrice=${priceRange[1]}`;
    }

    if (commissionRange.length === 2) {
      path += `&firstCommission=${commissionRange[0]}&lastCommission=${commissionRange[1]}`;
    }

    const { data } = await api.get<TMarketplacePagination>(path);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getAffiliatedByProductId = async ({ productId }: { productId: string }): Promise<TAffiliated | IErrorResponse> => {
  try {
    const { data } = await api.get<TAffiliated>(`/Affiliation/affiliated/product/${productId}`);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getSimpleAffiliatedsList = async (): Promise<TAffiliatedSimple[] | IErrorResponse> => {
  try {
    const { data } = await api.get<TAffiliatedSimple[]>(`/Affiliation/affiliated/list`);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const getAffiliationCommissions = async ({ id }: { id: string }): Promise<TAffiliatedCommission[] | IErrorResponse> => {
  try {
    const { data } = await api.get<TAffiliatedCommission[]>(`/Affiliation/affiliated/commission/${id}/history`);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const updateAffiliatedStatus = async ({ id, status }: { id: string, status: TAffiliatedStatus }): Promise<IResponse> => {
  try {
    const json = { id, status };
    const { data: response } = await api.put("/Affiliation/affiliated/status", json);

    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
}

const getProductAffiliation = async ({ code }: { code: string }): Promise<TProductAffiliates | IErrorResponse> => {
  try {
    const { data } = await api.get<TProductAffiliates>(`/Affiliation/product/${code}/Affiliation/details`);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
}

const AffiliationController = {
  insertProduct,
  updateProduct,
  removeProduct,
  getAll,
  getAllBlocked,
  getProduct,
  getMarketplace,
  getSimpleAffiliatedsList,
  getAffiliatedByProductId,
  insertAffiliated,
  updateAffiliated,
  updateAffiliatedStatus,
  updateCommission,
  getProducts,
  enabledCommission,
  getAffiliationCommissions,
  getProductAffiliation,
};

export default AffiliationController;
