import moment from "moment";

export const oneSecondInMilleseconds = 1000;

export const oneMinuteInMilliseconds = oneSecondInMilleseconds * 60;

export const oneHourInMilliseconds = oneMinuteInMilliseconds * 60;

export const yearsExpires = () => {
    const now = new Date();
    const year = now.getFullYear();
    const years: number[] = [];

    for (var i = year; i < year + 10; i++) {
        years.push(Number(i.toString().substring(2, 4)));
    }

    return years;
}

export const dateTimeString = (dateTime?: Date) => {
    if (!dateTime) {
        return "";
    }
    return moment(dateTime).format("DD/MM/yyyy HH:mm");
}

export const dateTimeSecondsString = (dateTime?: Date) => {
    if (!dateTime) {
        return "";
    }
    return moment(dateTime).format("DD/MM/yyyy HH:mm:ss");
}

export const dateString = (dateTime?: Date) => {
    if (!dateTime) {
        return "";
    }
    return moment(dateTime).format("DD/MM/yyyy");
}

export const addDays = (days: number, dateTime?: Date) => {
    dateTime ??= new Date();
    const current = new Date(dateTime);
    current.setDate(current.getDate() + days);
    return current;
}

export const diffDays = (dateA: Date, dateB: Date) => {
    const timeA = dateA.getTime();
    const timeB = dateB.getTime();

    //const diff = Math.abs(timeA - timeB);
    const diff = timeA - timeB;
    const diffInDays = Math.ceil(diff / (1000 * 3600 * 24));

    //console.log({ dateA, dateB, timeA, timeB, diff, diffInDays });

    return diffInDays;
}