import { Box, Button, Container, Fab, Typography } from "@mui/material";
import CheckoutComponenetList from "../../../components/CustomCheckout/CheckoutComponentList";
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentItemSX,
  TCustomCheckoutLayout,
  TModelType,
  TPopupConfig,
} from "../../../core/types/CustomCheckout";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
export interface ICustomCheckoutLayoutProps {
  layout: TCustomCheckoutLayout;
  popConfig: TPopupConfig;
  checkoutSettings: TCustomCheckoutComponentItemSX;
  tabIndex: (value: number) => void;
  list: TCustomCheckoutComponentItem[];
  dragOverItem: React.MutableRefObject<string>;
  dragOverItemOld: React.MutableRefObject<string>;
  parentList: TCustomCheckoutComponentItem[];
  mouseEnterItemId: string;
  setList: (value: TCustomCheckoutComponentItem[]) => void;
  onEdit: (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.DragEvent<HTMLLIElement>,
    value: TCustomCheckoutComponentItem
  ) => void;
  onDelete: (value: TCustomCheckoutComponentItem) => void;
  setCurrentComponenets: (
    value: TCustomCheckoutComponentItem[] | undefined
  ) => void;
  setMouseEnterItemId: React.Dispatch<React.SetStateAction<string>>;
  onDropItem: (
    e: React.DragEvent<HTMLDivElement>,
    dragItem: React.MutableRefObject<string>
  ) => void;
}

const CustomCheckoutLayout = ({
  layout,
  popConfig,
  checkoutSettings,
  list,
  dragOverItem,
  dragOverItemOld,
  parentList,
  mouseEnterItemId,
  setList,
  onEdit,
  onDelete,
  setCurrentComponenets,
  setMouseEnterItemId,
  onDropItem,
  tabIndex,
}: ICustomCheckoutLayoutProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          overflowY: "scroll",
          maxHeight: "87vh",
        }}
      >
        <Box
          sx={{
            backgroundColor: checkoutSettings.backgroundColor,
            backgroundImage: `url("${checkoutSettings.backgroundImage}")`,
            backgroundRepeat: checkoutSettings.backgroundImageRepeat
              ? "repeat"
              : "no-repeat",
            backgroundPositionX: checkoutSettings.backgroundImageRepeat
              ? "start"
              : "center",
            backgroundPositionY: checkoutSettings.backgroundImageRepeat
              ? "start"
              : checkoutSettings.backgroundImageFixedTop
              ? "start"
              : "center",
            backgroundSize: checkoutSettings.backgroundImageExpand
              ? "cover"
              : "auto",
            backgroundAttachment: checkoutSettings.backgroundImageFixedTop
              ? "fixed"
              : "scroll",
          }}
        >
          <Container
            maxWidth={layout === TCustomCheckoutLayout.mobile ? "xs" : "md"}
          >
            <CheckoutComponenetList
              layout={layout}
              list={list}
              dragOverItem={dragOverItem}
              dragOverItemOld={dragOverItemOld}
              parentList={parentList}
              mouseEnterItemId={mouseEnterItemId}
              setList={setList}
              onEdit={onEdit}
              onDelete={onDelete}
              setCurrentComponenets={setCurrentComponenets}
              setMouseEnterItemId={setMouseEnterItemId}
              onDropItem={onDropItem}
            />
          </Container>

          {popConfig.modelType !== TModelType.none && (
            <Box
              sx={{
                position: "absolute",
                border: "2px dotted grey",
                padding: 2,
                backgroundColor: "white",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "auto",
                bottom: 20,
                right: 550,
              }}
            >
              <Typography align="center" color="Black">
                FUNCIONALIDADE
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AutoAwesomeMotionIcon />}
                onClick={() => tabIndex(2)}
                sx={{
                  marginTop: 1,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                Exit Popup
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CustomCheckoutLayout;
