import { Routes as Router, Route } from "react-router-dom";
import AutoAuthRoutes from "./AutoAuthRoutes";
import UnauthorizedPage from "../pages/Unauthorized";
import AdminProtected from "./ProtectedRoutes/AdminProtected";
import SysAdminProtected from "./ProtectedRoutes/SysAdminProtected";
import CustomCheckoutPage from "../pages/CustomCheckout";

type Props = {};

const Routes = (props: Props) => {
  return (
    <Router>
      <Route index element={<UnauthorizedPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />

      <Route element={<AdminProtected />}>
        <Route path="/customcheckout/:id" element={<CustomCheckoutPage />} />

        <Route element={<SysAdminProtected />}>

        </Route>

      </Route>

    </Router>
  );
};

export default Routes;
