import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Breakpoint, CircularProgress, Stack } from "@mui/material";

export interface IConfirmDialogProps {
  open: boolean;
  actions?: boolean | undefined;
  title?: string;
  children?: React.ReactNode;
  onClose: () => void;
  labelPrositive?: string;
  onPositive?: () => Promise<boolean>;
  maxWidth?: false | Breakpoint | undefined;
  hasCancel?: boolean | undefined;
  isTitle?: boolean;
}

export default function ContentDialog(props: IConfirmDialogProps) {
  const { open, actions, title, children, onClose, labelPrositive, onPositive, maxWidth, hasCancel, isTitle } = props;
  const isCancel = hasCancel === undefined ? true : hasCancel;
  const [loading, setLoading] = React.useState(false);

  const handleYes = async () => {
    setLoading(true);
    try {
      var close = true;

      if (onPositive && onPositive !== null) {
        close = await onPositive();
      }

      if (close) {
        onClose();
      }
    } finally {
      setLoading(false);
    }
  };

  const Loading = () => {
    return (
      <Stack
        direction="column"
        sx={{ p: 12 }}
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth || "sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {((isTitle === undefined) || (isTitle === true)) && (
        <DialogTitle id="alert-dialog-title">
          {title || "Celetus"}
        </DialogTitle>
      )}
      <DialogContent>{loading ? <Loading /> : children}</DialogContent>
      {((actions === undefined) || (actions === true)) && (
        <DialogActions>
          {isCancel && <Button variant="outlined" onClick={onClose}>Cancelar</Button>}
          <Button
            autoFocus
            disabled={loading}
            variant="contained"
            onClick={handleYes}
          >
            {labelPrositive || "OK"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
