import theme from "../../layouts/Theme";
import { TCustomCheckoutComponentItem, TCustomCheckoutComponentType } from "../types/CustomCheckout";
import NumberFunctions from "./NumberFunctions";

const getComponent = (parent: TCustomCheckoutComponentItem | undefined, values: TCustomCheckoutComponentItem[], id: string): {
    parent: TCustomCheckoutComponentItem | undefined,
    components: TCustomCheckoutComponentItem[] | undefined,
    component: TCustomCheckoutComponentItem | undefined,
    index: number,
} => {
    let components: TCustomCheckoutComponentItem[] | undefined = undefined;
    let component: TCustomCheckoutComponentItem | undefined = undefined;
    let index: number = 0;

    values.map((value, i) => {
        if (component === undefined) {
            if (value.id === id) {
                components = values;
                component = value;
                index = i;
            }
        }

        if (component === undefined) {
            const { parent: p, components: cs, component: c, index: i } = getComponent(value, value.children, id);
            if (cs && c) {
                parent = p;
                components = cs;
                component = c;
                index = i;
            }
        }
    });

    return { parent, components, component, index };
}

function getNewUpdateList(list: TCustomCheckoutComponentItem[], updateItem: TCustomCheckoutComponentItem): TCustomCheckoutComponentItem[] {
    const newList: TCustomCheckoutComponentItem[] = [];
    list.map((item) => {
        if (item.id === updateItem.id) {
            newList.push({ ...updateItem });
        } else {
            const children = getNewUpdateList(item.children, updateItem);
            newList.push({ ...item, children: children });
        }
    });
    return newList;
}

const updateList = (list: TCustomCheckoutComponentItem[], updateItem: TCustomCheckoutComponentItem): TCustomCheckoutComponentItem[] => {
    return getNewUpdateList(list, updateItem);
}

function getNewDeleteList(list: TCustomCheckoutComponentItem[], deleteItem: TCustomCheckoutComponentItem): TCustomCheckoutComponentItem[] {
    const newList: TCustomCheckoutComponentItem[] = [];
    list.map((item) => {
        if (item.id !== deleteItem.id) {
            const children = getNewDeleteList(item.children, deleteItem);
            newList.push({ ...item, children });
        }
    });
    return newList;
}

function addItemListEmpty(list: TCustomCheckoutComponentItem[]) {
    list.map((item) => {
        if (item.type === TCustomCheckoutComponentType.list && item.children.length <= 0) {
            item.children.push({
                id: NumberFunctions.getRandomInt(999999).toString(),
                name: "Header",
                order: 1,
                type: TCustomCheckoutComponentType.none,
                sx: {
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary,
                },
                title: "",
                subtitle: "",
                fixedTop: false,
                children: [],
            });
        } else if (item.children.length > 0) {
            item.children = addItemListEmpty(item.children);
        }
        return true;
    });
    return list;
}

const deleteItemList = (list: TCustomCheckoutComponentItem[], deleteItem: TCustomCheckoutComponentItem): TCustomCheckoutComponentItem[] => {
    const items = getNewDeleteList(list, deleteItem);
    return addItemListEmpty(items);
}

const clearEmpty = (list: TCustomCheckoutComponentItem[]) => {
    let newList = list.filter(l => l.type !== TCustomCheckoutComponentType.empty);
    newList = newList.map((item) => {
        const children = clearEmpty(item.children);
        return { ...item, children };
    });
    return newList;
}

const CheckoutFunctions = {
    getComponent,
    updateList,
    deleteItemList,
    addItemListEmpty,
    clearEmpty,
}

export default CheckoutFunctions;