export enum TTenantAnticipationType {
  None = 0,
  D2 = 2,
  D15 = 15,
  D30 = 30,
}

export type ITenant = {
  id: string;
  accessKey: string;
  name: string;
  email: string;
  status?: number;
  paymentServiceUrl?: string;
  memberServiceDomain?: string;
  memberServiceToken?: string;
  memberServiceApiKey?: string;
  token: string;
  createdDate?: Date;
  updatedDate?: Date;
}

export type ITenantBankAccount = {
  recipientDocument: string;
  accountName: string;
  bankCode: string;
  agencyNumber: string;
  accountNumber: string;
  accountNumberDigit: string;
  accountType: number;
  agencyNumberCheck?: number | null;
  tenantId?: string;
}

export type ITenantBalance = {
  availableAmount: number;
  waitingFundsAmount: number;
  transferredAmount: number;
  withdraws: IWithdraw[];
  recipientStatus?: string;
  immediateWithdrawal: number;
  anticipationType?: TTenantAnticipationType | null;
}

export type IWithdraw = {
  amount: number;
  createdAt: Date;
  status: WithdrawStatusEnum;
  updatedAt?: Date;
  details?: IWithdrawDetail[];
}

export type IWithdrawDetail = {
  detail: string;
  registerDate?: Date;
}

export enum WithdrawStatusEnum {
  pending = 1,
  approved = 2,
  processing = 3,
  fail = 4
}

export type TSignUpTenant = {
  email: string;
  emailConfirm?: string;
  password: string;
}

export type TTenantSettings = {
  id: string;
  tenantId: string;
  immediateWithdrawal: number;
  plataformFee: number;

  tenantCode?: string;
  tenantName?: string;
  tenantEmail?: string;

  enableAnticipationD15?: boolean | null;
  anticipationD15Fee?: number | null;
  enableAnticipationD2?: boolean | null;
  anticipationD2Fee?: number | null;
}

export type TTenantSettingsPagination = {
  items: TTenantSettings[];
  page: number;
  rowsPerPage: number;
  totalPages: number;
  total: number;
}