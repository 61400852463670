import { CircularProgress, Paper, Stack } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";

export interface ICheckoutImageProps {
    alt: string;
    imageUrl?: string;
}

const CheckoutImage = ({ alt, imageUrl }: ICheckoutImageProps) => {
    const [loaded, setLoaded] = useState(!imageUrl || imageUrl === "" ? true : false);

    const imageStyle = !loaded ? { display: "none" } : { width: "100%", borderRadius: "8px", margin: 0, padding: 0 };

    const handleImageLoaded = () => {
        setLoaded(true);
    };

    return (
        <Paper
            sx={{
                width: "100%",
                border: `2px solid ${blue[700]}`,
                borderStyle: "dashed",
                borderRadius: "8px",
                backgroundColor: "white",
                color: grey[800],
                p: 0,
                m: 0,
                margin: 0,
            }}
        >
            <Stack
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                }}
            >
                {/* <div style={{ position: "relative", width: "100%", margin: 0, padding: 0 }}> */}
                {!loaded && <CircularProgress />}
                {imageUrl && imageUrl !== undefined && (
                    <img src={imageUrl} alt={alt} style={imageStyle} onLoad={handleImageLoaded} />
                )}
                {(!imageUrl || imageUrl === "") && <ImageIcon sx={{ fontSize: "2rem", color: "silver" }} />}
                {/* </div> */}
            </Stack>
        </Paper>
    );
};

export default CheckoutImage;
