import { createContext, ReactNode, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { TAuthContext, TAuthUser } from "../types/Auth";
import Cookies from "js-cookie";
import { addDays } from "../functions/dateTime";

type Props = {
  children?: ReactNode;
};

const initialValue = {
  user: null,
  setUser: (newUser: TAuthUser | null) => { },
};

const AuthContext = createContext<TAuthContext>(initialValue);
const cookieUserName = "__apu";

const AuthProvider = ({ children }: Props) => {
  const userCookie = Cookies.get(cookieUserName);
  const [user, setAuthUser] = useState<TAuthUser | null>(userCookie && userCookie !== ""
    ? JSON.parse(userCookie)
    : null
  );
  const [userLocal, setUserLocal] = useLocalStorage("user", null);

  const setUser = (newUser: TAuthUser | null) => {
    if (newUser) {
      const expires = newUser.exp ? newUser.exp : addDays(1);
      Cookies.set(cookieUserName, JSON.stringify(newUser), {
        expires,
        domain: process.env.REACT_APP_COOKIE_HOST,
      });
    } else {
      Cookies.remove(cookieUserName);
    }

    setAuthUser(newUser);
  }

  if (userLocal) {
    setUser(userLocal as TAuthUser);
    setUserLocal(null);
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
