import { createTheme } from "@mui/material";

export const primaryColor = "#1C52BD";

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: primaryColor,
            900: "#1A3579",
            800: "#21469D",
            700: "#3057BE",
        },
        secondary: {
            main: "#F6D240",
            900: "#473800",
            800: "#5C4A00",
            700: "#715D00",
        },
    },
    typography: {
        fontFamily: "Roboto",
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "::-ms-reveal": {
                        display: "none",
                    },
                    "::-ms-clear": {
                        display: "none",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "::-ms-reveal": {
                        display: "none",
                    },
                    "::-ms-clear": {
                        display: "none",
                    },
                },
            },
        },
    },
});

export default theme;
