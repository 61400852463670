import { Grid, Paper, Stack, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutLayout,
} from "../../core/types/CustomCheckout";
import CheckoutComponenetWidget from "./CheckoutComponentWidget";
import { useRef } from "react";

export interface ICheckoutLayout2_3Props {
  layout: TCustomCheckoutLayout;
  item: TCustomCheckoutComponentItem;
  parentList: TCustomCheckoutComponentItem[];
  mouseEnterItemId: string;
  dragOverItem: React.MutableRefObject<string>;
  dragOverItemOld: React.MutableRefObject<string>;
  setList: (value: TCustomCheckoutComponentItem[]) => void;
  onEditItem: (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.DragEvent<HTMLLIElement>,
    value: TCustomCheckoutComponentItem
  ) => void;
  onDeleteItem: (value: TCustomCheckoutComponentItem) => void;
  setCurrentComponenets: (
    value: TCustomCheckoutComponentItem[] | undefined
  ) => void;
  setMouseEnterItemId: React.Dispatch<React.SetStateAction<string>>;
  onDropItem: (
    e: React.DragEvent<HTMLDivElement>,
    dragItem: React.MutableRefObject<string>
  ) => void;
}

const CheckoutLayout2_3 = ({
  layout,
  item,
  parentList,
  mouseEnterItemId,
  dragOverItem,
  dragOverItemOld,
  setList,
  onEditItem,
  onDeleteItem,
  setCurrentComponenets,
  setMouseEnterItemId,
  onDropItem,
}: ICheckoutLayout2_3Props) => {
  const handleSetList = (value: TCustomCheckoutComponentItem[]) => {
    const newItem: TCustomCheckoutComponentItem = {
      ...item,
      children: [...value],
    };
    const newItems = parentList.map((list) =>
      list.id === newItem.id ? { ...newItem } : { ...list }
    );
    setList([...newItems]);
  };

  if (layout === TCustomCheckoutLayout.mobile) {
    return (
      <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
        {item.children.length > 0 && (
          <CheckoutComponenetWidget
            layout={layout}
            item={item.children[0]}
            parentList={parentList}
            mouseEnterItemId={mouseEnterItemId}
            dragOverItem={dragOverItem}
            dragOverItemOld={dragOverItemOld}
            setList={setList}
            onEdit={onEditItem}
            onDelete={onDeleteItem}
            setCurrentComponenets={setCurrentComponenets}
            setMouseEnterItemId={setMouseEnterItemId}
            onDropItem={onDropItem}
          />
        )}
        {item.children.length === 0 && (
          <Paper
            sx={{
              width: "100%",
              height: 120,
              border: `2px solid ${blue[700]}`,
              borderStyle: "dashed",
              borderRadius: "8px",
              backgroundColor: "white",
              color: grey[800],
            }}
          >
            <Stack
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              Arraste um componente aqui...
            </Stack>
          </Paper>
        )}
        {item.children.length > 1 && item.children[1].children.length > 0 && (
          <Paper
            sx={{
              width: "100%",
              height: item.children[1]?.sx.height ?? 120,
              border: `2px solid ${blue[700]}`,
              borderStyle: "dashed",
              borderRadius: "8px",
              backgroundColor: "white",
              color: grey[800],
            }}
          >
            <Stack
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <CheckoutComponenetWidget
                layout={layout}
                item={item.children[1]}
                dragOverItem={dragOverItem}
                dragOverItemOld={dragOverItemOld}
                parentList={item.children[1].children}
                mouseEnterItemId={mouseEnterItemId}
                setList={(e) => handleSetList(e)}
                onEdit={onEditItem}
                onDelete={onDeleteItem}
                setCurrentComponenets={setCurrentComponenets}
                setMouseEnterItemId={setMouseEnterItemId}
                onDropItem={onDropItem}
              />
            </Stack>
          </Paper>
        )}
      </Stack>
    );
  }

  return (
    <Grid container columns={3} spacing={2}>
      <Grid item sm={3} md={2}>
        {item.children.length > 0 && (
          <CheckoutComponenetWidget
            layout={layout}
            item={item.children[0]}
            parentList={parentList}
            mouseEnterItemId={mouseEnterItemId}
            dragOverItem={dragOverItem}
            dragOverItemOld={dragOverItemOld}
            setList={setList}
            onEdit={onEditItem}
            onDelete={onDeleteItem}
            setCurrentComponenets={setCurrentComponenets}
            setMouseEnterItemId={setMouseEnterItemId}
            onDropItem={onDropItem}
          />
        )}
        {item.children.length === 0 && (
          <Paper
            sx={{
              width: "100%",
              height: 120,
              border: `2px solid ${blue[700]}`,
              borderStyle: "dashed",
              borderRadius: "8px",
              backgroundColor: "white",
              color: grey[800],
            }}
          >
            <Stack
              alignContent="center"
              alignItems="center"
              justifyContent="center"
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              Arraste um componente aqui...
            </Stack>
          </Paper>
        )}
      </Grid>
      <Grid item sm={3} md={1}>
        <Paper
          sx={{
            width: "100%",
            height: item.children[1]?.sx.height ?? 120,
            border: `2px solid ${blue[700]}`,
            borderStyle: "dashed",
            borderRadius: "8px",
            backgroundColor: "white",
            color: grey[800],
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {item.children.length > 1 &&
              item.children[1].children.length <= 0 && (
                <Typography>Arraste um componente aqui...</Typography>
              )}
            {item.children.length > 1 &&
              item.children[1].children.length > 0 && (
                <CheckoutComponenetWidget
                  layout={layout}
                  item={item.children[1]}
                  dragOverItem={dragOverItem}
                  dragOverItemOld={dragOverItemOld}
                  parentList={item.children[1].children}
                  mouseEnterItemId={mouseEnterItemId}
                  setList={(e) => handleSetList(e)}
                  onEdit={onEditItem}
                  onDelete={onDeleteItem}
                  setCurrentComponenets={setCurrentComponenets}
                  setMouseEnterItemId={setMouseEnterItemId}
                  onDropItem={onDropItem}
                />
              )}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CheckoutLayout2_3;
