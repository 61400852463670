import { Paper } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

export interface ICheckoutHeaderProps {
    height?: string | number;
}

const CheckoutEmpty = ({ height = 30 }: ICheckoutHeaderProps) => {
    return (
        <Paper
            elevation={0}
            sx={{
                width: "100%",
                height: height,
                border: `2px solid ${blue[500]}`,
                borderStyle: "dotted",
                borderRadius: "8px",
                backgroundColor: grey[200],
            }}
        ></Paper>
    );
};

export default CheckoutEmpty;
