import { Alert, Snackbar } from "@mui/material";

export interface IErrorSnackbarProps {
    open: boolean;
    children: string | JSX.Element;
    onClose: () => void;
}

const ErrorSnackbar = ({ open, onClose, children }: IErrorSnackbarProps) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                variant="filled"
                severity="error"
                onClose={onClose}
                sx={{ width: '100%' }}
            >
                {children}
            </Alert>
        </Snackbar>
    );
}

export default ErrorSnackbar;