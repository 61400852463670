export enum TUserType {
  SystemAdmin = "SystemAdmin",
  Administrator = "Administrator",
  Member = "Member",
}

export const userTypeSysAdmin = [TUserType.SystemAdmin];

export const userTypeNotSysAdmin = [TUserType.Administrator, TUserType.Member];

export const userTypeAll = [TUserType.SystemAdmin, TUserType.Administrator, TUserType.Member];

export type TAuthUser = {
  nameid: string;
  email: string;
  UserType: TUserType;
  TenantId: string;
  unique_name: string;
  token: string;
  exp?: Date;
};

export type TAuthContext = {
  user: TAuthUser | null;
  setUser: (newUser: TAuthUser | null) => void;
};
