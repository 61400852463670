import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { useEffect } from "react";
//import "@blocknote/core/style.css";
import "./blocknote.css";

type WindowWithProseMirror = Window & typeof globalThis & { ProseMirror: any };

export interface ICheckoutTextEditorProps {
    initialContent: string,
}

function CheckoutTextView({ initialContent }: ICheckoutTextEditorProps) {

    const getInitialContent = () => {
        try {
            return JSON.parse(initialContent);
        } catch (e) {
            return [];
        }
    }

    // Creates a new editor instance.
    const view: BlockNoteEditor = useBlockNote({
        initialContent: getInitialContent(),
        editable: false,
        domAttributes: {
            blockContainer: {
                "padding": "0px",
                "margin": "0px",
            },
            blockContent: {
                "padding": "0px",
                "margin": "0px",
            },
        }
    });

    useEffect(() => {
        if (view) {
            const blocks: Block[] = getInitialContent();

            blocks.map((newBlock) => {
                const top = view.topLevelBlocks.find(top => top.id === newBlock.id);
                if (top) {
                    view.updateBlock(top.id, newBlock);
                }
            });

            view.topLevelBlocks.map((top) => {
                const exists = blocks.find(b => b.id === top.id);
                if (!exists) {
                    view.removeBlocks([top]);
                }
            });

            let i = 0;
            while (i < blocks.length) {
                if (blocks[i].id !== view.topLevelBlocks[i].id) {
                    view.insertBlocks([blocks[i]], view.topLevelBlocks[i]);
                }
                i += 1;
            }
        }
    }, [initialContent]);

    // Give tests a way to get prosemirror instance
    (window as WindowWithProseMirror).ProseMirror = view?._tiptapEditor;

    // Renders the editor instance using a React component.
    return <BlockNoteView editor={view} theme="light" />;

}

export default CheckoutTextView;