import api, { getResponseError } from "../../services/api";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";
import { TProductVoucher } from "../types/Voucher";

const getProductVouchers = async ({
  productId,
}: {
  productId: string;
}): Promise<TProductVoucher[] | IErrorResponse> => {
  try {
    var url = `/ProductVoucher/voucher/${productId}/list?OnlyActive=true`;

    const { data } = await api.get<TProductVoucher[]>(url);
    if (data) {
      return data;
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error) {
    const e = getResponseError(error);
    return {
      code: 2,
      error: e,
    };
  }
};

const insertVoucher = async ({
  data,
}: {
  data: TProductVoucher;
}): Promise<IResponse> => {
  try {
    const response = await api.post("/ProductVoucher/voucher", data);
    return {
      success: true,
      code: 0,
      error: "",
      data: response.data,
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const updateVoucher = async ({
  data,
}: {
  data: TProductVoucher;
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put("/ProductVoucher/voucher", data);
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      };
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const removeVoucher = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    await api.delete(`/ProductVoucher/voucher/${id}`);
    return {
      success: true,
      code: 0,
      error: "",
    };
  } catch (error: any) {
    const e = getResponseError(error);
    return {
      success: false,
      code: 2,
      error: e,
    };
  }
};

const VoucherController = {
  getProductVouchers,
  insertVoucher,
  updateVoucher,
  removeVoucher,
};

export default VoucherController;
