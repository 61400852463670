import { BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import "@blocknote/core/style.css";
import { Box } from "@mui/material";

type WindowWithProseMirror = Window & typeof globalThis & { ProseMirror: any };

export interface ICheckoutTextEditorProps {
    initialContent: string,
    onChange: (value: string) => void;
}

function CheckoutTextEditor({ initialContent, onChange }: ICheckoutTextEditorProps) {

    const getInitialContent = () => {
        try {
            return JSON.parse(initialContent);
        } catch (e) {
            return [];
        }
    }

    // Creates a new editor instance.
    const editor: BlockNoteEditor = useBlockNote({
        initialContent: getInitialContent(),
        onEditorContentChange: async (editor) => {
            // Log the document to console on every update
            const json = JSON.stringify(editor.topLevelBlocks);
            onChange(json);
        },
    });

    // Give tests a way to get prosemirror instance
    (window as WindowWithProseMirror).ProseMirror = editor?._tiptapEditor;

    // Renders the editor instance using a React component.
    return (
        <Box sx={{ pt: 3 }}>
            <BlockNoteView editor={editor} theme="light" />
        </Box>
    );

}

export default CheckoutTextEditor;