import { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Slide,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import DrawerHeader from "../../layouts/DrawerHeader";
import NotesIcon from "@mui/icons-material/Notes";
import ImageIcon from "@mui/icons-material/Image";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import TimerIcon from "@mui/icons-material/Timer";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CloseIcon from "@mui/icons-material/Close";
import HandymanIcon from "@mui/icons-material/Handyman";
import NumberFunctions from "../../core/functions/NumberFunctions";
import theme from "../../layouts/Theme";
import SwitchRound from "../../components/Switch/SwitchRound";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";
import ColorChoose from "../../components/Inputs/ColorChoose";
import {
  TClickAction,
  TCustomCheckout,
  TCustomCheckoutComponent,
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentItemSX,
  TCustomCheckoutComponentType,
  TCustomCheckoutLayout,
  TModelType,
  TPopupConfig,
} from "../../core/types/CustomCheckout";
import CheckoutFunctions from "../../core/functions/CheckoutFunctions";
import { dateTimeSecondsString } from "../../core/functions/dateTime";
import { useParams } from "react-router-dom";
import CustomCheckoutController from "../../core/controllers/CustomCheckoutController";
import LoadingBackdrop from "../../components/Loading/LoadingBackdrop";
import ErrorSnackbar from "../../components/Snackbar/ErrorSnackbar";
import SuccessSnackbar from "../../components/Snackbar/SuccessSnackbar";
import ImageChoose from "../../components/Images/ImageChoose";
import ImageController from "../../core/controllers/ImageController";
import { TImageUploaded } from "../../core/types/Image";
import CustomCheckoutLayout from "./Layouts/CustomCheckoutLayout";
import {
  customCheckoutDefault,
  newCustomCheckoutText,
} from "../../core/constants";
import ContentDialog from "../../components/Dialogs/ContentDialog";
import ImageCrop from "../../components/Images/ImageCrop";
import NotFoundPage from "../NotFound";
import CheckoutTextEditor from "../../components/CustomCheckout/CheckoutTextEditor";
import { grey } from "@mui/material/colors";
import { useProductVouchers } from "../../core/hooks/useProductVouchers";
import { useProductPrices } from "../../core/hooks/useProductPrices";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const drawerWidth = 500;

const components: TCustomCheckoutComponent[] = [
  {
    name: "Texto",
    icon: <NotesIcon />,
    type: TCustomCheckoutComponentType.text,
  },
  {
    name: "Imagem",
    icon: <ImageIcon />,
    type: TCustomCheckoutComponentType.image,
  },
  {
    name: "Cronômetro",
    icon: <TimerIcon />,
    type: TCustomCheckoutComponentType.stopWatch,
  },
];

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomCheckoutPage = () => {
  const { id } = useParams<{ id: string }>();

  const [layout, setLayout] = useState<TCustomCheckoutLayout>(
    TCustomCheckoutLayout.desktop
  );
  const [data, setData] = useState<TCustomCheckout>({
    ...customCheckoutDefault,
  });

  const [deleteItem, setDeleteItem] = useState<
    TCustomCheckoutComponentItem | undefined
  >(undefined);
  const [editItem, setEditItem] = useState<
    TCustomCheckoutComponentItem | undefined
  >(undefined);

  const [tabIndex, setTabIndex] = useState(0);

  const [openCopy, setOpenCopy] = useState(false);
  const [openImageCrop, setOpenImageCrop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);

  const [error, setError] = useState("");
  const [errorSending, setErrorSending] = useState("");
  const [mouseEnterItemId, setMouseEnterItemId] = useState("");

  const dragOverItemOld = useRef("");
  const dragOverItem = useRef("");
  const dragItemComponent = useRef(-1);
  const dropItemDate = useRef(new Date());

  const list =
    layout === TCustomCheckoutLayout.desktop
      ? data.desktopLayout?.components ?? []
      : data.mobileLayout?.components ?? [];

  const checkoutSettings =
    layout === TCustomCheckoutLayout.desktop
      ? data.desktopLayout?.settings ?? {}
      : data.mobileLayout?.settings ?? {};

  const popupSettings: TPopupConfig =
    layout === TCustomCheckoutLayout.desktop
      ? data.desktopLayout?.popupConfig ?? { modelType: TModelType.none }
      : data.mobileLayout?.popupConfig ?? { modelType: TModelType.none };

  const setList = (value: TCustomCheckoutComponentItem[]) => {
    if (layout === TCustomCheckoutLayout.desktop) {
      if (data.desktopLayout) {
        setData({
          ...data,
          desktopLayout: { ...data.desktopLayout, components: [...value] },
        });
      }
    } else {
      if (data.mobileLayout) {
        setData({
          ...data,
          mobileLayout: { ...data.mobileLayout, components: [...value] },
        });
      }
    }
  };

  const setCheckoutSettings = (value: TCustomCheckoutComponentItemSX) => {
    if (layout === TCustomCheckoutLayout.desktop) {
      if (data.desktopLayout) {
        setData({
          ...data,
          desktopLayout: { ...data.desktopLayout, settings: { ...value } },
        });
      }
    } else {
      if (data.mobileLayout) {
        setData({
          ...data,
          mobileLayout: { ...data.mobileLayout, settings: { ...value } },
        });
      }
    }
  };

  // ========================================

  const { data: dataVoucher } = useProductVouchers({
    productId: data.productId,
  });

  const { data: dataPrices } = useProductPrices({ productId: data.productId });

  const handleImagePopup = async (
    file: File | undefined,
    code: string | undefined
  ) => {
    if (!file && !code) {
      return;
    }

    try {
      let response;
      let image: TImageUploaded | undefined | null;
      if (file) {
        if (popupSettings.imageCode) {
          await ImageController.deleteImage({
            code: popupSettings.imageCode,
          });
        }
        response = await ImageController.upload(file);
        image = response.data;
      } else if (code) {
        response = await ImageController.deleteImage({ code });
      }

      if (!response) {
        return;
      }

      if (!response.success) {
        setErrorSending(response.error);
        return;
      }
      setPopupSettings({
        ...popupSettings,
        imageUrl: image?.url,
        imageCode: image?.code,
        imageName: image?.name,
        imageExt: image?.extension,
      });
    } finally {
      setSending(false);
    }
  };

  const setPopupSettings = (value: TPopupConfig) => {
    if (layout === TCustomCheckoutLayout.desktop) {
      if (data.desktopLayout) {
        setData({
          ...data,
          desktopLayout: { ...data.desktopLayout, popupConfig: { ...value } },
        });
      }
    } else {
      if (data.mobileLayout) {
        setData({
          ...data,
          mobileLayout: { ...data.mobileLayout, popupConfig: { ...value } },
        });
      }
    }
  };

  console.log("DATA ->", data);

  // =========================================

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const dragStartComponent = (
    e: React.DragEvent<HTMLLIElement>,
    position: number
  ) => {
    dragItemComponent.current = position;
  };

  const dragEnterComponent = (
    e: React.DragEvent<HTMLLIElement>,
    position: number
  ) => {
    //dragOverItem.current = position;
  };

  const dropComponent = (e: React.DragEvent<HTMLLIElement>) => {
    if (dragOverItem.current === "") {
      return;
    }

    const item = components[dragItemComponent.current];
    const {
      parent,
      components: items,
      component: itemCurrent,
      index,
    } = CheckoutFunctions.getComponent(undefined, list, dragOverItem.current);

    if (items && itemCurrent) {
      let copyListItems = [...items];
      let newItem: TCustomCheckoutComponentItem | undefined = undefined;

      const newId = NumberFunctions.getRandomInt(999999).toString();
      switch (item.type) {
        case TCustomCheckoutComponentType.text:
          newItem = { ...newCustomCheckoutText, id: newId };
          break;
        case TCustomCheckoutComponentType.image:
          newItem = {
            id: newId,
            name: "Imagem",
            order: 0,
            type: item.type,
            sx: {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
            },
            title: "",
            subtitle: "",
            fixedTop: false,
            children: [],
          };
          break;
        case TCustomCheckoutComponentType.stopWatch:
          newItem = {
            id: newId,
            name: "Cronômetro",
            order: 0,
            type: item.type,
            sx: {
              backgroundColor: "#F44747",
              color: "white",
            },
            title: "Oferta por tempo limitado",
            subtitle: "O tempo acabou!",
            fixedTop: false,
            hours: 0,
            minutes: 15,
            seconds: 0,
            milleseconds: 900000,
            children: [],
          };
          break;
      }

      if (newItem) {
        copyListItems.splice(
          index,
          itemCurrent.type === TCustomCheckoutComponentType.none ? 1 : 0,
          newItem
        );
      }

      dragItemComponent.current = -1;
      dragOverItem.current = "";

      copyListItems = CheckoutFunctions.clearEmpty(copyListItems);

      let order = 0;
      copyListItems.map((item) => {
        order += 1;
        item.order = order;
      });

      copyListItems = copyListItems.sort(
        (a, b) => (a.order ?? 0) - (b.order ?? 0)
      );

      if (!parent) {
        setList(copyListItems);
      } else {
        let newList = CheckoutFunctions.clearEmpty(list);
        newList = newList.map((l) =>
          l.id === parent.id
            ? { ...parent, children: copyListItems }
            : {
                ...l,
                children: l.children.map((c) =>
                  c.id === parent.id
                    ? { ...parent, children: copyListItems }
                    : { ...c }
                ),
              }
        );
        setList(newList);
      }

      if (newItem) {
        setEditItem(newItem);
      }

      e.preventDefault();
    }
  };

  const dropItem = (
    e: React.DragEvent<HTMLDivElement>,
    dragItem: React.MutableRefObject<string>
  ) => {
    if (
      dateTimeSecondsString(dropItemDate.current) ===
      dateTimeSecondsString(new Date())
    ) {
      return;
    }

    dropItemDate.current = new Date();
    const {
      parent,
      components: items,
      component: itemCurrent,
      index: indexOld,
    } = CheckoutFunctions.getComponent(undefined, list, dragItem.current);
    const { index } = CheckoutFunctions.getComponent(
      undefined,
      list,
      dragOverItem.current
    );

    if (items && itemCurrent) {
      var copyListItems = [...items];
      copyListItems.splice(indexOld, 1);

      copyListItems.splice(index, 0, itemCurrent);
      dragItem.current = "";
      dragOverItem.current = "";

      var order = 0;
      copyListItems.map((item) => {
        order += 1;
        item.order = order;
      });

      copyListItems = copyListItems.sort(
        (a, b) => (a.order ?? 0) - (b.order ?? 0)
      );

      if (!parent) {
        setList(copyListItems);
      } else {
        const newList = list.map((l) =>
          l.id === parent.id
            ? { ...parent, children: copyListItems }
            : {
                ...l,
                children: l.children.map((c) =>
                  c.id === parent.id
                    ? { ...parent, children: copyListItems }
                    : { ...c }
                ),
              }
        );
        setList(newList);
      }
      e.preventDefault();
    }
  };

  const handleDeleteItem = (item: TCustomCheckoutComponentItem) => {
    setDeleteItem(item);
  };

  const deleteRegister = async () => {
    if (deleteItem) {
      const newItems = CheckoutFunctions.deleteItemList(list, deleteItem);
      setList([...newItems]);
      setDeleteItem(undefined);
      setEditItem(undefined);
    }
    return true;
  };

  const handleEditItem = (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.DragEvent<HTMLLIElement>,
    item: TCustomCheckoutComponentItem
  ) => {
    setEditItem(item);
    e.preventDefault();
  };

  const handleChangeSave = async () => {
    console.log("data ->", data);
    setSending(true);
    try {
      const response = await CustomCheckoutController.update({ data });
      if (!response.success) {
        setErrorSending(response.error);
        return;
      }
      setSuccess(true);
      getCustomCheckout(id);
    } finally {
      setSending(false);
    }
  };

  const handleChangeBackgroundImage = async (
    file: File | undefined,
    code: string | undefined
  ) => {
    if (!file && !code) {
      return;
    }

    //setOpenImageCrop(true);

    setSending(true);
    try {
      let response;
      let image: TImageUploaded | undefined | null;

      if (file) {
        if (checkoutSettings.backgroundImageCode) {
          await ImageController.deleteImage({
            code: checkoutSettings.backgroundImageCode,
          });
        }
        response = await ImageController.upload(file);
        image = response.data;
      } else if (code) {
        response = await ImageController.deleteImage({ code });
      }

      if (!response) {
        return;
      }

      if (!response.success) {
        setErrorSending(response.error);
        return;
      }

      setCheckoutSettings({
        ...checkoutSettings,
        backgroundImage: image?.url,
        backgroundImageCode: image?.code,
        backgroundImageName: image?.name,
        backgroundImageExt: image?.extension,
      });
    } finally {
      setSending(false);
    }
  };

  const handleChangeImage = async (
    file: File | undefined,
    code: string | undefined
  ) => {
    if (!file && !code) {
      return;
    }
    if (!editItem) {
      return;
    }

    //setOpenImageCrop(true);

    setSending(true);
    try {
      let response;
      let image: TImageUploaded | undefined | null;

      if (file) {
        if (editItem.imageCode) {
          await ImageController.deleteImage({ code: editItem.imageCode });
        }
        response = await ImageController.upload(file);
        image = response.data;
      } else if (code) {
        response = await ImageController.deleteImage({ code });
      }

      if (!response) {
        return;
      }

      if (!response.success) {
        setErrorSending(response.error);
        return;
      }

      const updateItem = {
        ...editItem,
        imageUrl: image?.url,
        imageCode: image?.code,
        imageName: image?.name,
        imageExt: image?.extension,
      };

      setEditItem({ ...updateItem });
      const newList = CheckoutFunctions.updateList(list, updateItem);
      setList([...newList]);
    } finally {
      setSending(false);
    }
  };

  const handleCopyLayout = () => {
    setOpenCopy(true);
  };

  const copyLayout = () => {
    if (layout === TCustomCheckoutLayout.desktop) {
      if (data.mobileLayout) {
        const layout = { ...data.mobileLayout };
        layout.settings = data.mobileLayout.settings;
        layout.components = data.mobileLayout.components;
        setData({ ...data, desktopLayout: layout });
      }
    }
    if (layout === TCustomCheckoutLayout.mobile) {
      if (data.desktopLayout) {
        const layout = { ...data.desktopLayout };
        layout.settings = data.desktopLayout.settings;
        layout.components = data.desktopLayout.components;
        setData({ ...data, mobileLayout: layout });
      }
    }
  };

  const getCustomCheckout = useCallback(
    async (customCheckoutId?: string) => {
      if (
        customCheckoutId === undefined ||
        customCheckoutId === null ||
        customCheckoutId === ""
      ) {
        return;
      }

      setLoading(false);
      setError("");
      try {
        const response = await CustomCheckoutController.get({
          id: customCheckoutId,
        });
        if (!response.success) {
          setError(response.error);
        }

        if (response.data) {
          if (!response.data.desktopLayout) {
            response.data.desktopLayout = customCheckoutDefault.desktopLayout;
          }
          if (!response.data.mobileLayout) {
            response.data.mobileLayout = customCheckoutDefault.mobileLayout;
          }
          setData(response.data);
        }
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, setData]
  );

  useEffect(() => {
    getCustomCheckout(id);
  }, [id]);

  if (error === "Request failed with status code 404") {
    return <NotFoundPage />;
  }

  return (
    <Box>
      <Box sx={{ width: `calc(100% - ${drawerWidth}px)` }}>
        <Box
          sx={{
            width: "100%",
            height: 50,
            borderTop: 1,
            borderBottom: 1,
            borderColor: "divider",
            px: 3,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Grid container columns={12}>
            <Grid item xs={3}>
              <Stack
                direction="row"
                alignContent="center"
                alignItems="center"
                sx={{ width: "100%", height: 50 }}
              >
                <Typography noWrap sx={{ fontSize: 16 }}>
                  {data.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction="row"
                spacing={2}
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%", height: 50 }}
              >
                <Button
                  variant={
                    layout === TCustomCheckoutLayout.desktop
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setLayout(TCustomCheckoutLayout.desktop);
                    setEditItem(undefined);
                  }}
                  sx={{ borderRadius: 10, textTransform: "none" }}
                >
                  <Stack direction="row" spacing={1}>
                    <DesktopMacIcon />
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "none",
                          xl: "block",
                        },
                      }}
                    >
                      Monitor
                    </Box>
                  </Stack>
                </Button>
                <Button
                  variant={
                    layout === TCustomCheckoutLayout.mobile
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setLayout(TCustomCheckoutLayout.mobile);
                    setEditItem(undefined);
                  }}
                  sx={{ borderRadius: 10, textTransform: "none" }}
                >
                  <Stack direction="row" spacing={1}>
                    <PhoneIphoneIcon />
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          md: "none",
                          lg: "none",
                          xl: "block",
                        },
                      }}
                    >
                      Celular
                    </Box>
                  </Stack>
                </Button>
                <Button onClick={handleCopyLayout} sx={{ width: 180 }}>
                  Copiar de{" "}
                  {layout === TCustomCheckoutLayout.desktop
                    ? " Mobile"
                    : " Desktop"}
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack
                direction="row"
                alignContent="center"
                alignItems="center"
                justifyContent="end"
                sx={{ width: "100%", height: 50 }}
              >
                <Button variant="contained" onClick={handleChangeSave}>
                  Salvar Checkout
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <CustomCheckoutLayout
          layout={layout}
          popConfig={popupSettings}
          tabIndex={setTabIndex}
          checkoutSettings={checkoutSettings}
          list={list}
          dragOverItem={dragOverItem}
          dragOverItemOld={dragOverItemOld}
          parentList={list}
          mouseEnterItemId={mouseEnterItemId}
          setList={setList}
          onEdit={handleEditItem}
          onDelete={handleDeleteItem}
          setCurrentComponenets={(value) => {
            //currentComponenets.current = value;
          }}
          setMouseEnterItemId={setMouseEnterItemId}
          onDropItem={dropItem}
        />
      </Box>

      <Drawer
        variant="permanent"
        open={true}
        anchor="right"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Slide
          direction="right"
          in={editItem === undefined}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ width: "100%", position: "absolute", top: 0, left: 0 }}>
            <DrawerHeader />
            <Divider />
            <Stack
              direction="row"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ flexGrow: 1 }}
              >
                <Tab icon={<AddCircleOutlineIcon />} {...a11yProps(0)} />
                <Tab icon={<FormatPaintIcon />} {...a11yProps(1)} />
                <Tab icon={<AutoAwesomeMotionIcon />} {...a11yProps(2)} />
              </Tabs>
            </Stack>
            <TabPanel value={tabIndex} index={0}>
              <Box>
                <Grid container spacing={2} columns={12}>
                  {components.map((value, index) => (
                    <Grid
                      key={value.name}
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={4}
                    >
                      <ListItem
                        disableGutters
                        draggable={true}
                        key={`item${index}`}
                        onDragStart={(e) => dragStartComponent(e, index)}
                        onDragEnter={(e) => dragEnterComponent(e, index)}
                        onDragEnd={(e) => dropComponent(e)}
                      >
                        <Paper
                          sx={{
                            width: "100%",
                            ":hover": {
                              boxShadow: 10,
                            },
                          }}
                        >
                          <Button fullWidth sx={{ textTransform: "none" }}>
                            <Stack
                              alignContent="center"
                              alignItems="center"
                              alignSelf="center"
                              sx={{ p: 1 }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                alignContent="center"
                                alignItems="center"
                                alignSelf="center"
                              >
                                {value.icon}
                                {value.name !== "" && (
                                  <Typography
                                    variant="caption"
                                    textAlign="center"
                                    fontWeight={500}
                                  >
                                    {value.name}
                                  </Typography>
                                )}
                              </Stack>
                            </Stack>
                          </Button>
                        </Paper>
                      </ListItem>
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Typography variant="body2" color={grey[600]}>
                      Arraste um desses componentes para a área pontilhada para
                      editar as opções do elemento.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <ColorChoose
                name="Cor de fundo"
                color={checkoutSettings.backgroundColor}
                onChange={(value) =>
                  setCheckoutSettings({
                    ...checkoutSettings,
                    backgroundColor: value,
                  })
                }
              />
              <Box sx={{ pt: 2 }}>
                <ImageChoose
                  title="Imagem de fundo"
                  imageUrl={checkoutSettings.backgroundImage}
                  imageCode={checkoutSettings.backgroundImageCode}
                  imageName={checkoutSettings.backgroundImageName}
                  onChange={handleChangeBackgroundImage}
                  onError={(e) => setError(e)}
                />
                <FormControlLabel
                  control={
                    <SwitchRound
                      sx={{ m: 1 }}
                      checked={
                        checkoutSettings.backgroundImageFixedTop ?? false
                      }
                      onChange={(e) =>
                        setCheckoutSettings({
                          ...checkoutSettings,
                          backgroundImageFixedTop: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Fixar imagem de fundo ao descer a tela"
                />
                <FormControlLabel
                  control={
                    <SwitchRound
                      sx={{ m: 1 }}
                      checked={checkoutSettings.backgroundImageRepeat ?? false}
                      onChange={(e) =>
                        setCheckoutSettings({
                          ...checkoutSettings,
                          backgroundImageRepeat: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Repetir imagem de fundo"
                />
                <FormControlLabel
                  control={
                    <SwitchRound
                      sx={{ m: 1 }}
                      checked={checkoutSettings.backgroundImageExpand ?? false}
                      onChange={(e) =>
                        setCheckoutSettings({
                          ...checkoutSettings,
                          backgroundImageExpand: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Expandir / cobrir fundo"
                />
              </Box>
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              {/* ======================= ==========================================================================*/}

              <Box p={2}>
                <Typography variant="h6">Configuração do Exit Popup</Typography>
              </Box>

              <Box p={2}>
                <Typography>Escolha um modelo</Typography>
                <Select
                  value={popupSettings.modelType}
                  onChange={(e) =>
                    setPopupSettings({
                      ...popupSettings,
                      modelType: e.target.value as TModelType,
                    })
                  }
                  displayEmpty
                  fullWidth
                  sx={{ marginTop: "8px", marginBottom: "15px" }}
                >
                  <MenuItem value="" disabled>
                    Selecione um modelo
                  </MenuItem>
                  <MenuItem value={1}>Nenhum</MenuItem>
                  <MenuItem value={2}>Imagem</MenuItem>
                  <MenuItem value={3}>Imagem e Texto</MenuItem>
                </Select>

                {(popupSettings.modelType === TModelType.image ||
                  popupSettings.modelType === TModelType.imageText) && (
                  <Stack spacing={2} mt={2}>
                    <InputLabel id="voucher-select-label">
                      Ação do Click
                    </InputLabel>
                    <Select
                      labelId="voucher-select-label"
                      value={popupSettings.actionClick}
                      onChange={(e) =>
                        setPopupSettings({
                          ...popupSettings,
                          actionClick: e.target.value as TClickAction,
                        })
                      }
                      displayEmpty
                      fullWidth
                    >
                      <MenuItem value={1}>Oferecer desconto</MenuItem>
                      <MenuItem value={2}>Redirecionar para URL</MenuItem>
                      <MenuItem value={3}>Apenas Fechar</MenuItem>
                    </Select>
                    {popupSettings.actionClick === 1 && (
                      <>
                        <InputLabel id="voucher-select-label">
                          Desconto
                        </InputLabel>
                        <Select
                          labelId="voucher-select-label"
                          value={popupSettings.actionValue}
                          onChange={(e) => {
                            setPopupSettings({
                              ...popupSettings,
                              actionValue: e.target.value,
                            });
                          }}
                        >
                          {dataVoucher.map((voucher) => (
                            <MenuItem
                              key={voucher.id}
                              value={voucher.voucherIdentifier}
                            >
                              {voucher.voucherIdentifier}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}

                    {popupSettings.actionClick === 2 && (
                      <>
                        <InputLabel id="Offer-select-label">Oferta</InputLabel>
                        <Select
                          labelId="Offer-select-label"
                          value={popupSettings.actionValue}
                          onChange={(e) => {
                            setPopupSettings({
                              ...popupSettings,
                              actionValue: e.target.value,
                            });
                          }}
                        >
                          {dataPrices.map((offer) => (
                            <MenuItem key={offer.codeId} value={offer.codeId}>
                              {offer.offerName}
                            </MenuItem>
                          ))}
                        </Select>
                      </>
                    )}

                    {popupSettings.modelType === TModelType.image && (
                      <>
                        <InputLabel id="button-text">Texto do Botão</InputLabel>
                        <TextField
                          value={popupSettings.textButton}
                          onChange={(e) => {
                            setPopupSettings({
                              ...popupSettings,
                              textButton: e.target.value,
                            });
                          }}
                          fullWidth
                        />
                        <ImageChoose
                          title="Imagem do Popup"
                          imageUrl={popupSettings.imageUrl}
                          imageCode={popupSettings.imageCode}
                          imageName={popupSettings.imageName}
                          onChange={handleImagePopup}
                          onError={(e) => setError(e)}
                        />
                      </>
                    )}
                    {popupSettings.modelType === TModelType.imageText && (
                      <>
                        <InputLabel id="text-main">Texto Principal</InputLabel>
                        <TextField
                          value={popupSettings.mainText}
                          onChange={(e) =>
                            setPopupSettings({
                              ...popupSettings,
                              mainText: e.target.value,
                            })
                          }
                          fullWidth
                        />
                        <InputLabel id="text-aux">
                          Texto Auxiliar (Opcional)
                        </InputLabel>
                        <TextField
                          value={popupSettings.auxText}
                          onChange={(e) =>
                            setPopupSettings({
                              ...popupSettings,
                              auxText: e.target.value,
                            })
                          }
                          fullWidth
                        />
                        <InputLabel id="text-button-2">
                          Texto do Botão
                        </InputLabel>
                        <TextField
                          value={popupSettings.textButton}
                          onChange={(e) =>
                            setPopupSettings({
                              ...popupSettings,
                              textButton: e.target.value,
                            })
                          }
                          fullWidth
                        />
                        <ImageChoose
                          title="Imagem de fundo"
                          imageUrl={popupSettings.imageUrl}
                          imageCode={popupSettings.imageCode}
                          imageName={popupSettings.imageName}
                          onChange={handleImagePopup}
                          onError={(e) => setError(e)}
                          dimenssionsText="Dimensões recomendadas: 900x600 pixels"
                        />
                      </>
                    )}
                  </Stack>
                )}
              </Box>
            </TabPanel>
          </Box>
        </Slide>
        <Slide
          direction="left"
          in={editItem !== undefined}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ width: "100%", position: "absolute", top: 0, left: 0 }}>
            <DrawerHeader />
            <Box
              sx={{
                width: "100%",
                height: 50,
                borderTop: 1,
                borderBottom: 1,
                borderColor: "divider",
                px: 3,
                backgroundColor: theme.palette.background.default,
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignContent="center"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: "100%", height: "100%" }}
              >
                <HandymanIcon color="primary" />
                <Typography>Editar</Typography>
                <IconButton onClick={() => setEditItem(undefined)}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              {editItem?.type === TCustomCheckoutComponentType.layout2_3 && (
                <Box sx={{ mt: 3 }}>
                  <Typography>
                    Se a coluna não conter componente será removido, deixando
                    apenas o checkout visível.
                  </Typography>
                </Box>
              )}
              {editItem?.type === TCustomCheckoutComponentType.text && (
                <Box>
                  <CheckoutTextEditor
                    initialContent={editItem?.title ?? "[]"}
                    onChange={(e) => {
                      if (editItem) {
                        const updateItem = { ...editItem, title: e };
                        setEditItem(updateItem);
                        const newList = CheckoutFunctions.updateList(
                          list,
                          updateItem
                        );
                        setList([...newList]);
                      }
                    }}
                  />
                </Box>
              )}
              {editItem?.type === TCustomCheckoutComponentType.image && (
                <Box sx={{ mt: 3 }}>
                  <ImageChoose
                    title="Imagem"
                    imageUrl={editItem.imageUrl}
                    imageCode={editItem.imageCode}
                    imageName={editItem.imageName}
                    onChange={handleChangeImage}
                    onError={(e) => setError(e)}
                  />
                </Box>
              )}
              {editItem?.type === TCustomCheckoutComponentType.stopWatch && (
                <>
                  <Grid container columns={10} sx={{ pt: 3 }}>
                    <Grid item xs={4} sm={5} md={5} lg={5} xl={5}>
                      <ColorChoose
                        name="Cor de fundo"
                        color={editItem?.sx.backgroundColor}
                        onChange={(value) => {
                          if (editItem) {
                            const updateItem = {
                              ...editItem,
                              sx: { ...editItem.sx, backgroundColor: value },
                            };
                            setEditItem(updateItem);
                            const newList = CheckoutFunctions.updateList(
                              list,
                              updateItem
                            );
                            setList([...newList]);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={5} md={5} lg={5} xl={5}>
                      <ColorChoose
                        name="Cor do texto"
                        color={editItem?.sx.color}
                        onChange={(value) => {
                          if (editItem) {
                            const updateItem = {
                              ...editItem,
                              sx: { ...editItem.sx, color: value },
                            };
                            setEditItem(updateItem);
                            const newList = CheckoutFunctions.updateList(
                              list,
                              updateItem
                            );
                            setList([...newList]);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} columns={12} sx={{ mt: 1 }}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <FormControl fullWidth size="small">
                        <Typography>Horas</Typography>
                        <Select
                          variant="outlined"
                          size="small"
                          value={editItem?.hours ?? 0}
                          onChange={(e) => {
                            if (editItem) {
                              const hours = Number(e.target.value);
                              const updateItem = {
                                ...editItem,
                                hours,
                                milleseconds:
                                  hours * 1000 * 60 * 60 +
                                  (editItem?.minutes ?? 0) * 1000 * 60 +
                                  (editItem?.seconds ?? 0) * 1000,
                              };
                              setEditItem(updateItem);
                              const newList = CheckoutFunctions.updateList(
                                list,
                                updateItem
                              );
                              setList([...newList]);
                            }
                          }}
                        >
                          {Array.from({ length: 24 })
                            .map((a, i) => i)
                            .map((i) => (
                              <MenuItem value={i}>{i}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <FormControl fullWidth size="small">
                        <Typography>Minutos</Typography>
                        <Select
                          variant="outlined"
                          size="small"
                          value={editItem?.minutes ?? 0}
                          onChange={(e) => {
                            if (editItem) {
                              const minutes = Number(e.target.value);
                              const updateItem = {
                                ...editItem,
                                minutes,
                                milleseconds:
                                  (editItem?.hours ?? 0) * 1000 * 60 * 60 +
                                  minutes * 1000 * 60 +
                                  (editItem?.seconds ?? 0) * 1000,
                              };
                              setEditItem(updateItem);
                              const newList = CheckoutFunctions.updateList(
                                list,
                                updateItem
                              );
                              setList([...newList]);
                            }
                          }}
                        >
                          {Array.from({ length: 60 })
                            .map((a, i) => i)
                            .map((i) => (
                              <MenuItem value={i}>{i}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <FormControl fullWidth size="small">
                        <Typography>Segundos</Typography>
                        <Select
                          variant="outlined"
                          size="small"
                          value={editItem?.seconds ?? 0}
                          onChange={(e) => {
                            if (editItem) {
                              const seconds = Number(e.target.value);
                              const updateItem = {
                                ...editItem,
                                seconds,
                                milleseconds:
                                  (editItem?.hours ?? 0) * 1000 * 60 * 60 +
                                  (editItem?.minutes ?? 0) * 1000 * 60 +
                                  seconds * 1000,
                              };
                              setEditItem(updateItem);
                              const newList = CheckoutFunctions.updateList(
                                list,
                                updateItem
                              );
                              setList([...newList]);
                            }
                          }}
                        >
                          {Array.from({ length: 60 })
                            .map((a, i) => i)
                            .map((i) => (
                              <MenuItem value={i}>{i}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <FormControl fullWidth size="small" sx={{ mt: 3 }}>
                    <Typography>Texto contagem ativa</Typography>
                    <TextField
                      fullWidth
                      required
                      multiline
                      size="small"
                      type="text"
                      value={editItem?.title ?? ""}
                      onChange={(e) => {
                        if (editItem) {
                          const updateItem = {
                            ...editItem,
                            title: e.target.value,
                          };
                          setEditItem(updateItem);
                          const newList = CheckoutFunctions.updateList(
                            list,
                            updateItem
                          );
                          setList([...newList]);
                        }
                      }}
                      //error={errorName !== ""}
                    />
                  </FormControl>
                  <FormControl fullWidth size="small" sx={{ mt: 3 }}>
                    <Typography>Texto contagem zerada</Typography>
                    <TextField
                      fullWidth
                      required
                      multiline
                      size="small"
                      type="text"
                      value={editItem?.subtitle ?? ""}
                      onChange={(e) => {
                        if (editItem) {
                          const updateItem = {
                            ...editItem,
                            subtitle: e.target.value,
                          };
                          setEditItem(updateItem);
                          const newList = CheckoutFunctions.updateList(
                            list,
                            updateItem
                          );
                          setList([...newList]);
                        }
                      }}
                      //error={errorName !== ""}
                    />
                  </FormControl>
                  <FormControlLabel
                    sx={{ mt: 3 }}
                    control={
                      <SwitchRound
                        sx={{ m: 1 }}
                        checked={editItem?.fixedTop ?? false}
                        onChange={(e) => {
                          if (editItem) {
                            const updateItem = {
                              ...editItem,
                              fixedTop: e.target.checked,
                            };
                            setEditItem(updateItem);
                            const newList = CheckoutFunctions.updateList(
                              list,
                              updateItem
                            );
                            setList([...newList]);
                          }
                        }}
                      />
                    }
                    label="Fixar no topo"
                  />
                </>
              )}
            </Box>
          </Box>
        </Slide>
      </Drawer>

      <ConfirmDialog
        open={deleteItem !== undefined}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o componente?"
        onClose={() => setDeleteItem(undefined)}
        onYes={deleteRegister}
      />

      <ConfirmDialog
        open={openCopy}
        title="Copiar layout"
        message="Deseja realmente copiar?"
        onClose={() => setOpenCopy(false)}
        onYes={copyLayout}
      >
        <Alert
          variant="filled"
          severity="warning"
          sx={{ width: "100%", mt: 3 }}
        >
          Essa ação substituirá o layout atual
        </Alert>
      </ConfirmDialog>

      <ContentDialog
        open={openImageCrop}
        onClose={() => setOpenImageCrop(false)}
      >
        <ImageCrop />
      </ContentDialog>

      <LoadingBackdrop open={loading || sending} />

      <ErrorSnackbar
        open={errorSending !== ""}
        onClose={() => setErrorSending("")}
      >
        {errorSending}
      </ErrorSnackbar>

      <ErrorSnackbar open={error !== ""} onClose={() => setError("")}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>
    </Box>
  );
};

export default CustomCheckoutPage;
